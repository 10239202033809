import React, { useEffect, useState, useContext } from "react";

import { AppStateContext } from './../components/AppStateProvider';
import API from './../classes/Api';
import Loading from './../components/Loading';
import Project from "../components/Project";
import Login from "../components/Login";
import { Route, useRouteMatch, Switch, useParams, useHistory } from "react-router-dom";
import Header from "../components/Header";

import CreateVote from "../components/CreateVote";

function ProjectDetails({projects, logout, setShowCreateProject, deleteProject, deleteCreative, AddSnackbar, user, updateProjectCreatives}) {
    let { projectId } = useParams();

    return(
        <div className="we-voting-backend">
            <Header user={user} logout={logout}>
                <button className="we-btn accent" onClick={() => setShowCreateProject(true)}>Neue Umfrage</button>
            </Header>
            {projects.length > 0 ? 
            <ul className="we-projects">{projects.filter(project => parseInt(project.id) === parseInt(projectId)).map((project, index) => <Project key={`PROJECT_W_${project.id}`} project={project} deleteProject={deleteProject} deleteCreative={deleteCreative} AddSnackbar={AddSnackbar} updateProjectCreatives={updateProjectCreatives} expand={true} />)}</ul>
            : ""}
        </div>
    )
}

export default function Backend({user, setUser, logout}) {
    const [isLoading, setIsLoading] = useState( true );
    const [projects, setProjects] = useState([]);
    const [projectName, setProjectName] = useState("");
    const [projectDescription, setProjectDescription] = useState("");
    const { AddSnackbar } = useContext(AppStateContext);
    const [showCreateProject, setShowCreateProject] = useState( false );

    let { path, url } = useRouteMatch();
    let history = useHistory();

    useEffect(function () {
        if( isLoading && user ) {
            API.getProjects().then(response => {
                var storageUser = API.getLocalStorageUser();
                if (response.success) {
                    setProjects(response.projects.map(project => {
                        Object.defineProperty(project, 'filter', {
                            value: {minAge: null, maxAge: null},
                            writable: true
                        });
                        return project;
                    }), setIsLoading( setUser( storageUser), false ));
                } else {
                    setIsLoading( setUser( storageUser), false );
                }
            });
        }

        if( user === null ) {
            setIsLoading(false);
        }
        

    }, [projects, isLoading]);

    function loginHandler(response) {
        if( !response ) { return; }
        if( response.success ) {
          setUser({token: response.token, id: response.uid, img: response.img, firstname: response.firstname, lastname: response.lastname });
        }
    }

    const deleteCreative = (projectId, creativeId ) => {
        API.deleteCreative( creativeId ).then(response => {
            if( response.success ) {
                AddSnackbar("🗑 Bild wurde gelöscht");
                var updatedProjects = projects.map( project => {
                    if( project.id === projectId ) {
                        project.creatives = project.creatives.filter(creative => creative.id !== creativeId );
                    }
                    return project;
                });
                setProjects( updatedProjects );
            }
        });
    }

    const updateProjectCreatives = (projectId, creatives ) => {
        var updatedProjects = projects.map( project => {
            if( project.id === projectId ) {
                project.creatives = creatives;
            }
            return project;
        });
        setProjects( updatedProjects );
    }

    const deleteProject = (id) => {
        API.deleteProject(id).then(response => {
            if( response.success ) {
                AddSnackbar("🗑 Umfrage wurde gelöscht");
                var updatedProjects = projects.filter( project => project.id !== id );
                setProjects( updatedProjects, history.push("/backend") );
            }
        });
    }

    const createProjectSubmitHandler = (e) => {
        e.preventDefault();
        var formData = new FormData( e.target );
        API.createProject( projectName, projectDescription, formData.getAll("images[]") ).then(response => {
            if( response.success ) {
                var newProjectId = response && response.data && response.data.id ? response.data.id : "";
                AddSnackbar("💾 Umfrage wurde angelegt");
                console.log( response );
                var updatedProjects = [...projects];
                updatedProjects.push( response.data );
                setProjects( updatedProjects, 
                    setShowCreateProject( false, history.push(`/backend/${newProjectId}`)
                    ) 
                );
            }
        });
    }
    
    if( isLoading ) {
    return( <Loading />);
    }
    
    
    if( !isLoading && user === null ) {
    return (
        <>
        <Login loginHandler={loginHandler} />
        </> 
    );
    }

    return (
        <Switch>
            <Route exact path={path}>
                {console.log( (!showCreateProject && projects.length <= 0) )}
                <Header user={user} logout={logout}>
                    <button className="we-btn accent" onClick={() => setShowCreateProject(true)}>Neue Umfrage</button>
                </Header>
                <div className="we-voting-backend">
                    {!showCreateProject ? 
                        (projects.length <= 0) ? 
                        <p className="we-voting-backend__empty">Keine Umfragen vorhanden</p>
                        : 
                        <ul className="we-projects">{projects.map((project, index) => <Project key={`PROJECT_W_${project.id}`} project={project} deleteProject={deleteProject} deleteCreative={deleteCreative} AddSnackbar={AddSnackbar} updateProjectCreatives={updateProjectCreatives} />)}</ul>
                    : ""}
                    {showCreateProject ? 
                        <CreateVote
                        createProjectSubmitHandler={createProjectSubmitHandler}
                        projectName={projectName}
                        setProjectName={setProjectName}
                        projectDescription={projectDescription}
                        setProjectDescription={setProjectDescription}
                        setShowCreateProject={setShowCreateProject}
                        />
                        : ""}
                </div>
            </Route>
            <Route path={`${path}/:projectId`}>
                <ProjectDetails 
                    projects={projects}
                    logout={logout}
                    setShowCreateProject={setShowCreateProject}
                    deleteProject={deleteProject}
                    deleteCreative={deleteCreative}
                    AddSnackbar={AddSnackbar}
                    user={user}
                    updateProjectCreatives={updateProjectCreatives}
                />
            </Route>
        </Switch>
    )
}