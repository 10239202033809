import React, {useContext, useState, useEffect} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Snackbar from './components/Snackbar';
import Backend from "./pages/Backend";
import Vote from "./pages/Vote";
import { AppStateContext } from './components/AppStateProvider';
import Header from "./components/Header";
import API from "./classes/Api";

export default function App() {
  const [user, setUser] = useState( null );
  const { Snackbars, CloseSnackbar } = useContext(AppStateContext);
  const [isLoading, setIsLoading] = useState( true );

  function logout() {
    API.logout();
    setUser(null);
  }

  useEffect(function () {
    if( isLoading ) {
        var storageUser = API.getLocalStorageUser();
        if( !storageUser ) {
            setIsLoading( false );
        } else {
            setUser( storageUser , setIsLoading( false ) );
        }
    }
  }, [isLoading]);


  return (
    <Router>
      <main>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. 
            
            */}
        <Switch>
        <Route path={`${process.env.PUBLIC_URL}/backend`}>
            <Backend user={user} setUser={setUser} logout={logout} />
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/vote/:projectId`}>
            <Vote />
          </Route>
          <Route path={`${process.env.PUBLIC_URL}/`}>
            <Header user={user} />
            <div className="we-vote-login">
              <h1>Willkommen bei WE-Vote!</h1>
              <p>Melde dich an um ein neues Voting zu erstellen.</p>
              <Link to="/backend" className="we-btn white">Login</Link>
            </div>
          </Route>
        
        </Switch>
        <ul className="we-snackbars">
          {Snackbars.map(snackbar => <Snackbar key={`SNACKBAR_${snackbar.id}`} snackbar={snackbar} closeSnackbar={CloseSnackbar} />)}
        </ul>
      </main>
    </Router>
  );
} 