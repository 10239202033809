import React from "react";

export default function Creative( {projectId, creative, deleteCreative, isExpanded} ) {
    var dislikes = creative.dislikes || 0;
    var likes = creative.likes || 0;

    return(
        <li className={`we-creative${(likes - dislikes) <= 0 ? " is--muted" : ""}`}>
            <img src={`data:image/jpeg;base64,${creative.image}`} alt="" title="" />
            {isExpanded ? 
                <div className="we-creative__stats">
                    <p>
                        <span className="we-vote-btn like">
                            <svg id="Ebene_1" dataname="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.28 141.29"><polygon className="cls-1" points="141.28 0 31.78 25.36 69.44 63.03 65.72 59.3 0 125.02 16.26 141.28 81.98 75.57 115.92 109.51 141.28 0"/></svg>
                        </span>
                        {creative.likes}
                    </p>
                    <p>
                        <span className="we-vote-btn dislike">
                            <svg id="Ebene_1" dataname="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.28 141.29"><polygon className="cls-1" points="141.28 0 31.78 25.36 69.44 63.03 65.72 59.3 0 125.02 16.26 141.28 81.98 75.57 115.92 109.51 141.28 0"/></svg>
                        </span>
                        {creative.dislikes}
                    </p>
                    <button className="we-creative__delete-btn" onClick={() => deleteCreative(projectId, creative.id)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                    </button>
                </div> : ""
            }
            
        </li>
    )
}
