var md5 = require('md5');

var API = (function(module){
    //const API_URL = "http://localhost:8080/api/v1.0.0/";
    //const API_URL = "https://showroom.ws-epic.de/we-vote/api/v1.0.0/";
    const API_URL = "https://vote.ws-epic.de/api/v1.0.0/";

    const LOCAL_STORAGE_USER = "WE_VOTE_LOGIN_USER";

    function setLocalStorageUser( user = null ) {
        localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(user));
    }

    function getLocalStorageUser() {
        var storageUser = localStorage.getItem(LOCAL_STORAGE_USER);
        if( !storageUser ) {
            return storageUser;
        }
        console.log(storageUser)
        storageUser = JSON.parse(storageUser);
        storageUser.id = parseInt(storageUser.id);
        return storageUser;
    }

    function removeLocalStorageUser() {
        localStorage.removeItem(LOCAL_STORAGE_USER);
    }

    function userLogin(username, password, googleIdToken, googleLogin = true) {
      return fetch(API_URL, {
        method: "POST",
        //credentials: "include",
        referrerPolicy: 'no-referrer',
        // AkpUP19
        body: `task=login${username !== "" ? "&uname=" + username : ""}${password !== "" ? "&pwd=" + md5(password) : ""}${googleIdToken !== "" ? "&googleIdToken=" + googleIdToken : ""}`,
        headers: new Headers(
          { "content-type": "application/x-www-form-urlencoded" })
      }).then((response) => {
        return response.json();
        // do something awesome that makes the world a better place
      }).then((data) => {
        if (data.success && data.token) {
            setLocalStorageUser({token: data.token, id: data.uid, img: data.img, firstname: data.firstname });
        } 
        return data;
      });
  }

  function logout() {
      removeLocalStorageUser();
  }

    function getProjects() {
        return fetch(API_URL, {
            method: "POST",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: `task=getProjects`
      
          }).then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              return null;
            }
          }).then(response => {
            if( response.projects ) {
              response.projects = response.projects.map(project => {
                if( project.creatives ) {
                  project.creatives = project.creatives.map( creative => {
                    creative.weight = 0;
                    if( creative.votes ) {
                      creative.votes.map( vote => {
                        if( vote.user ) {
                          vote.user = JSON.parse(vote.user);
                          if( vote.user.age ) {
                            vote.user.age = parseInt(vote.user.age);
                          }
                        }
                      });
                      creative.likes = creative.votes.filter(vote => vote.result==="LIKE").length || 0;
                      creative.dislikes = creative.votes.filter(vote => vote.result==="DISLIKE").length || 0;
                    }
                    return creative;
                  });
                }
                return project;
              });
            }
            return response;
          });
    }

    function getProject(id) {
        return fetch(API_URL, {
            method: "POST",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: `task=getProject&id=${id}`
      
          }).then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              return null;
            }
          });
    }

    function getUserVotes(projectId, userToken) {
        return fetch(API_URL, {
            method: "POST",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: `task=getUserVotes&projectId=${projectId}&userToken=${userToken}`
      
          }).then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              return null;
            }
          });
    }

    function updateUserVotes(creativeId, userToken, result) {
        return fetch(API_URL, {
            method: "POST",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: `task=updateUserVotes&creativeId=${creativeId}&userToken=${userToken}&result=${result}`
      
          }).then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              return null;
            }
          });
    }

    function deleteProject( projectID ) {
        return fetch(API_URL, {
            method: "POST",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: `task=deleteProject&id=${projectID}`
      
          }).then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              return null;
            }
          });
    }

    function createProject( projectName, projectDesciption, images = [] ) {
        return fetch(API_URL, {
            method: "POST",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: `task=setProject&name=${projectName}&description=${projectDesciption}${images.length > 0 ? images.map(image => `&images[]=${image}`).join("") : ""}`
      
          }).then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              return null;
            }
          });
    }

    function deleteCreative( creativeId ) {
        return fetch(API_URL, {
            method: "POST",
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: `task=deleteCreative&id=${creativeId}`
      
          }).then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              return null;
            }
          });
    }

    function setCreatives( formData, cb ) {
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    cb( xhr );
                } else {
                    cb( {success: false} );
                }
            }
        }
        xhr.open('POST', API_URL, true);
        xhr.send(formData);
    }

    function setCreatives2( formData ) {
      return fetch(API_URL, {
        method: "POST",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: formData
  
      }).then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return null;
        }
      });
    }

    function setVote( creativeId, user, result ) {
      return fetch(API_URL, {
        method: "POST",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `task=setVote&creativeId=${creativeId}&user=${user}&result=${result}`
  
      }).then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return null;
        }
      });
    }

    module.setVote = setVote;
    module.getProject = getProject;
    module.deleteCreative = deleteCreative;
    module.setCreatives = setCreatives;
    module.setCreatives2 = setCreatives2;
    module.deleteProject = deleteProject;
    module.createProject = createProject;
    module.getProjects = getProjects;
    module.getUserVotes = getUserVotes;
    module.updateUserVotes = updateUserVotes;
    module.userLogin = userLogin;
    module.logout = logout;
    module.getLocalStorageUser = getLocalStorageUser;

    return module;
})(API || {});

export default API;
