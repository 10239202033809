import React, { useEffect, useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";

import Modal from "../components/Modal";
import AGB from "./AGB";
import API from "./../classes/Api";

import Loading from "./../components/Loading";
var md5 = require('md5');

function VoteStartSteps({
  userGender,
  setUserGender,
  userAge,
  setUserAge,
  startVoting,
  project,
}) {
  const [step, setStep] = useState("START");
  const [acceptAGB, setAcceptAGB] = useState( false );
  const [showAgbModal, setShowAgbModal] = useState( false );
  var content = "";
  switch (step) {
    case "START":
      content = (
        <div className="we-start">
          <div className="we-start__steps">
            <svg xmlns="http://www.w3.org/2000/svg" width="93.553" height="39.641" viewBox="0 0 93.553 39.641">
              <g id="Gruppe_1" data-name="Gruppe 1" transform="translate(-353.268 72.374)">
                <path id="Pfad_4" data-name="Pfad 4" d="M373.088-72.374a19.821,19.821,0,0,0-19.82,19.821,19.821,19.821,0,0,0,19.82,19.821,19.821,19.821,0,0,0,19.821-19.821A19.821,19.821,0,0,0,373.088-72.374Zm.664,27.918h-3.059a.489.489,0,0,1-.542-.39l-2.516-9.806L365.1-44.847a.48.48,0,0,1-.521.39h-3.059a.451.451,0,0,1-.477-.39l-3.492-14.4c-.065-.238,0-.39.238-.39h2.777a.447.447,0,0,1,.5.39l2.17,9.654,2.56-9.762a.35.35,0,0,1,.368-.282h2.929a.373.373,0,0,1,.39.282l2.538,9.762,2.191-9.654c.065-.238.152-.39.477-.39h2.755c.261,0,.326.152.261.39l-3.492,14.4A.444.444,0,0,1,373.752-44.456Zm14.483-14.209h-7.7l-1.449,6.139h6.941c.13,0,.19.065.156.217l-.107.52a.287.287,0,0,1-.3.217h-6.942l-1.45,6.139h7.7c.151,0,.19.065.156.216l-.131.542c-.013.152-.1.217-.257.217h-8.46c-.152,0-.212-.065-.178-.217l3.533-14.751c.013-.152.1-.217.278-.217h8.439c.152,0,.212.065.178.217l-.131.542A.251.251,0,0,1,388.236-58.666Z" transform="translate(0 0)" fill="#fff"/>
                <text id="VOTE" transform="translate(397.821 -46.452)" fill="#fff" fontSize="20" fontFamily="Crossten-BoldItalic, Crossten" fontWeight="700" fontStyle="italic"><tspan x="0" y="0">VOTE</tspan></text>
              </g>
            </svg>
            <p>Vote</p>
          </div>
          <div className="we-start__content">
            <h1>{project.name ? `${project.name}` : "Willkommen bei WE-Vote"}</h1>
            <p>{project.description ? project.description : ""}</p>
            <div>
              <label className="we-checkbox">
                <input type="checkbox" value={acceptAGB} onChange={ () => setAcceptAGB(!acceptAGB)}/>
                <span className="we-checkbox__input"></span>
                <span>
                  Um an der Umfreige teilnehmen zu können musst du unsere <button onClick={() => setShowAgbModal(true)}>AGB's</button> akzeptieren.
                </span>
              </label>
            </div>
            <button
              className="we-btn white"
              disabled={!acceptAGB}
              onClick={() => setStep("INFO")}
            >
              {console.log(project)}
              Los geht's!
            </button>
          </div>
        </div>
      );
      break;
    case "INFO":
      content = (
        <div className="we-start">
          <div className="we-start__steps">
            <svg xmlns="http://www.w3.org/2000/svg" width="93.553" height="39.641" viewBox="0 0 93.553 39.641">
              <g id="Gruppe_1" data-name="Gruppe 1" transform="translate(-353.268 72.374)">
                <path id="Pfad_4" data-name="Pfad 4" d="M373.088-72.374a19.821,19.821,0,0,0-19.82,19.821,19.821,19.821,0,0,0,19.82,19.821,19.821,19.821,0,0,0,19.821-19.821A19.821,19.821,0,0,0,373.088-72.374Zm.664,27.918h-3.059a.489.489,0,0,1-.542-.39l-2.516-9.806L365.1-44.847a.48.48,0,0,1-.521.39h-3.059a.451.451,0,0,1-.477-.39l-3.492-14.4c-.065-.238,0-.39.238-.39h2.777a.447.447,0,0,1,.5.39l2.17,9.654,2.56-9.762a.35.35,0,0,1,.368-.282h2.929a.373.373,0,0,1,.39.282l2.538,9.762,2.191-9.654c.065-.238.152-.39.477-.39h2.755c.261,0,.326.152.261.39l-3.492,14.4A.444.444,0,0,1,373.752-44.456Zm14.483-14.209h-7.7l-1.449,6.139h6.941c.13,0,.19.065.156.217l-.107.52a.287.287,0,0,1-.3.217h-6.942l-1.45,6.139h7.7c.151,0,.19.065.156.216l-.131.542c-.013.152-.1.217-.257.217h-8.46c-.152,0-.212-.065-.178-.217l3.533-14.751c.013-.152.1-.217.278-.217h8.439c.152,0,.212.065.178.217l-.131.542A.251.251,0,0,1,388.236-58.666Z" transform="translate(0 0)" fill="#fff"/>
                <text id="VOTE" transform="translate(397.821 -46.452)" fill="#fff" fontSize="20" fontFamily="Crossten-BoldItalic, Crossten" fontWeight="700" fontStyle="italic"><tspan x="0" y="0">VOTE</tspan></text>
              </g>
            </svg>
            <p>Info</p>
          </div>
          <div className="we-start__content">
            <p><b>Was passiert hier?</b></p>
            <p>
              Nachdem du anonym Geschlecht und Alter angegeben hast, werden dir
              nacheinander <b>{project.creatives.length}</b> Bilder zum Thema{" "}
              <b>{project.name ? project.name : ""}</b> angezeigt.
            </p>
            <p><b>Zeig uns deine Meinung:</b></p>
            <br />
            <div className="we-grid-2">
              <div>
                <span className="we-vote-btn dislike">
                  <svg id="Ebene_1" dataname="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.28 141.29"><polygon className="cls-1" points="141.28 0 31.78 25.36 69.44 63.03 65.72 59.3 0 125.02 16.26 141.28 81.98 75.57 115.92 109.51 141.28 0"/></svg>
                </span>
                gefällt mir weniger gut
              </div>
              <div>
                <span className="we-vote-btn like">
                  <svg id="Ebene_1" dataname="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.28 141.29"><polygon className="cls-1" points="141.28 0 31.78 25.36 69.44 63.03 65.72 59.3 0 125.02 16.26 141.28 81.98 75.57 115.92 109.51 141.28 0"/></svg>
                </span>
                gefällt mir gut
              </div>
            </div>
            
            <p><b>Du hast nur 1 Vote pro Bild!</b></p>
            <button
              className="we-btn we-btn--white is--round"
              onClick={() => setStep("PAGE1")}
            >
              Weiter
            </button>
          </div>
        </div>
      );
      break;
    case "PAGE1":
      content = (
        <div className="we-start">
          <div className="we-start__steps">
            <svg xmlns="http://www.w3.org/2000/svg" width="93.553" height="39.641" viewBox="0 0 93.553 39.641">
              <g id="Gruppe_1" data-name="Gruppe 1" transform="translate(-353.268 72.374)">
                <path id="Pfad_4" data-name="Pfad 4" d="M373.088-72.374a19.821,19.821,0,0,0-19.82,19.821,19.821,19.821,0,0,0,19.82,19.821,19.821,19.821,0,0,0,19.821-19.821A19.821,19.821,0,0,0,373.088-72.374Zm.664,27.918h-3.059a.489.489,0,0,1-.542-.39l-2.516-9.806L365.1-44.847a.48.48,0,0,1-.521.39h-3.059a.451.451,0,0,1-.477-.39l-3.492-14.4c-.065-.238,0-.39.238-.39h2.777a.447.447,0,0,1,.5.39l2.17,9.654,2.56-9.762a.35.35,0,0,1,.368-.282h2.929a.373.373,0,0,1,.39.282l2.538,9.762,2.191-9.654c.065-.238.152-.39.477-.39h2.755c.261,0,.326.152.261.39l-3.492,14.4A.444.444,0,0,1,373.752-44.456Zm14.483-14.209h-7.7l-1.449,6.139h6.941c.13,0,.19.065.156.217l-.107.52a.287.287,0,0,1-.3.217h-6.942l-1.45,6.139h7.7c.151,0,.19.065.156.216l-.131.542c-.013.152-.1.217-.257.217h-8.46c-.152,0-.212-.065-.178-.217l3.533-14.751c.013-.152.1-.217.278-.217h8.439c.152,0,.212.065.178.217l-.131.542A.251.251,0,0,1,388.236-58.666Z" transform="translate(0 0)" fill="#fff"/>
                <text id="VOTE" transform="translate(397.821 -46.452)" fill="#fff" fontSize="20" fontFamily="Crossten-BoldItalic, Crossten" fontWeight="700" fontStyle="italic"><tspan x="0" y="0">VOTE</tspan></text>
              </g>
            </svg>
            <p>Sex</p>
          </div>
          <div className="we-start__content sex">
            <p>Wähle dein Geschlecht.</p>
            <label className="we-checkbox">
              <input
                type="radio"
                value="weiblich"
                checked={userGender === "weiblich"}
                onChange={(e) => setUserGender(e.target.value)}
              />
              <span className="we-checkbox__input">&nbsp;</span>
              Weiblich
            </label>
            <label className="we-checkbox">
              <input
                type="radio"
                value="männlich"
                checked={userGender === "männlich"}
                onChange={(e) => setUserGender(e.target.value)}
              />
              <span className="we-checkbox__input">&nbsp;</span>
              Männlich
            </label>
            <label className="we-checkbox">
              <input
                type="radio"
                value="diverse"
                checked={userGender === "diverse"}
                onChange={(e) => setUserGender(e.target.value)}
                />
              <span className="we-checkbox__input">&nbsp;</span>
              Diverse
            </label>
          </div>
          <button
            className="we-btn we-btn--white is--round"
            onClick={() => setStep("PAGE2")}
            disabled={!userGender}
          >
            Weiter
          </button>
        </div>
      );
      break;
    case "PAGE2":
      content = (
        <div className="we-start">
          <div className="we-start__steps">
            <svg xmlns="http://www.w3.org/2000/svg" width="93.553" height="39.641" viewBox="0 0 93.553 39.641">
              <g id="Gruppe_1" data-name="Gruppe 1" transform="translate(-353.268 72.374)">
                <path id="Pfad_4" data-name="Pfad 4" d="M373.088-72.374a19.821,19.821,0,0,0-19.82,19.821,19.821,19.821,0,0,0,19.82,19.821,19.821,19.821,0,0,0,19.821-19.821A19.821,19.821,0,0,0,373.088-72.374Zm.664,27.918h-3.059a.489.489,0,0,1-.542-.39l-2.516-9.806L365.1-44.847a.48.48,0,0,1-.521.39h-3.059a.451.451,0,0,1-.477-.39l-3.492-14.4c-.065-.238,0-.39.238-.39h2.777a.447.447,0,0,1,.5.39l2.17,9.654,2.56-9.762a.35.35,0,0,1,.368-.282h2.929a.373.373,0,0,1,.39.282l2.538,9.762,2.191-9.654c.065-.238.152-.39.477-.39h2.755c.261,0,.326.152.261.39l-3.492,14.4A.444.444,0,0,1,373.752-44.456Zm14.483-14.209h-7.7l-1.449,6.139h6.941c.13,0,.19.065.156.217l-.107.52a.287.287,0,0,1-.3.217h-6.942l-1.45,6.139h7.7c.151,0,.19.065.156.216l-.131.542c-.013.152-.1.217-.257.217h-8.46c-.152,0-.212-.065-.178-.217l3.533-14.751c.013-.152.1-.217.278-.217h8.439c.152,0,.212.065.178.217l-.131.542A.251.251,0,0,1,388.236-58.666Z" transform="translate(0 0)" fill="#fff"/>
                <text id="VOTE" transform="translate(397.821 -46.452)" fill="#fff" fontSize="20" fontFamily="Crossten-BoldItalic, Crossten" fontWeight="700" fontStyle="italic"><tspan x="0" y="0">VOTE</tspan></text>
              </g>
            </svg>
            <p>Age</p>
          </div>
          <div className="we-start__content">
            <p>Wähle dein Alter.</p>
            <p className="we-start__age">{userAge}</p>
            <input
              className="we-range"
              type="range"
              value={userAge}
              min="10"
              max="80"
              onChange={(e) => setUserAge(e.target.value)}
            />
          </div>
          <button
            className="we-btn we-btn--white is--round"
            onClick={startVoting}
            disabled={userAge === 0}
          >
            Weiter
          </button>
        </div>
      );
      break;
      default: 
        content = "";
        break;
  }

  return ( 
    <>
      {showAgbModal ? 
        <Modal closeHandler={() => setShowAgbModal( false )}>
          <AGB />
        </Modal>
      :""}
      {content}
    </>
  );
}

export default function Vote() {
  const [isDisabled, setIsDisabled] = useState( false );
  const [isLoading, setIsLoading] = useState(true);
  const [userAge, setUserAge] = useState(0);
  const [userGender, setUserGender] = useState(null);
  const [user, setUser] = useState(null);
  const [userVotes, setUserVotes] = useState([]);
  
  const [finishVote, setFinishVote] = useState(false);
  const [showOverview, setShowOverview] = useState(false);
  
  const [isLight, setIsLight] = useState(false);
  const [project, setProject] = useState(null);
  const [currentCreative, setCurrentCreative] = useState(0);
  let { params } = useRouteMatch();
  var creative = null;

  const voteCreative = (creativeId, voteResult) => {
    setIsLoading( true, setIsDisabled( true ) );
    API.setVote(creativeId, JSON.stringify(user), voteResult).then(
      (response) => {
        if (response.success) {
          setCurrentCreative(currentCreative + 1, setIsLoading( false, setIsDisabled( false , checkFinishVote() ) ) );
        } else {
          setIsLoading( false, setIsDisabled( false ) )
        }
      }
    );
  };

  const startVoting = (e) => {
    e.preventDefault();
    var user = { age: userAge, gender: userGender, userToken: createUserToken() };
    if( user.userToken ) {
      localStorage.setItem("WE_VOTE_USER", user.userToken);
    }
    setUser(user);
  };

  const checkFinishVote = () => {
    if( !project ) { return; }
    if (currentCreative >= project.creatives.length - 1) { 
      setShowOverview( true );
    }
  }

  const createUserToken = () => {
    return md5(`WE_VOTE_${Math.floor(Date.now() / 1000)}_${userAge}_${userGender}_${Math.random() * (99999 - 0)}`);
  }

  const changeUserVote = (creative) => {
    var newResult = creative.vote.result === "LIKE" ? "DISLIKE" : "LIKE";
    var updatedUsderVotes = userVotes;
    if( creative.id && creative.vote.user.userToken ) {
      API.updateUserVotes( creative.id, creative.vote.user.userToken, newResult ).then(response => {
        if( response.success ) {
          updatedUsderVotes = userVotes.map( userVoteCreative => {
            if( userVoteCreative.id === creative.id ) {
              userVoteCreative.vote.result = newResult
            }
            return userVoteCreative;
          });
          setUserVotes( updatedUsderVotes );
        }
      });
    }
  }

  useEffect(
    function () {
      if (params.projectId && !project) {
        API.getProject(params.projectId).then((response) => {
          if (response.success) {
            setProject(response.projects[0], setIsLoading(false));
          }
        });
      }
      if( showOverview ) {
        setIsLoading( true );
        API.getUserVotes(project.id, localStorage.getItem("WE_VOTE_USER")).then(res => {
          if( res.success ) {
            console.log( res.creatives );
            setUserVotes( res.creatives, setIsLoading( false ) );
          }else {
            setIsLoading( false )
          }
        });
      }
      
      /*
            if( !user ) {
              var storageUser = localStorage.getItem("WE_VOTE_USER");
              if( storageUser ) {
                setUser(JSON.parse(storageUser));
              }
            }*/
    },
    [project, params.projectId, currentCreative]
  );

  if (isLoading && !project) {
    return <Loading />;
  }

  if (!project) {
    return (
      <div>
        <h2>Kein Projekt gefunden</h2>
      </div>
    );
  }

  if (!user) {
    return (
      <VoteStartSteps
        userGender={userGender}
        setUserGender={setUserGender}
        userAge={userAge}
        setUserAge={setUserAge}
        startVoting={startVoting}
        project={project}
      />
    );
    
  }
  if (project) {
    if (project.creatives[currentCreative]) {
      creative = project.creatives[currentCreative];
    }

    if( finishVote ) {
      return (
        <div className={`we-voting${isLight ? " is--light" : ""}`}>
          <div className="we-voting__wrapper we-voting__result">
            <div>
              <h2>Danke&nbsp;❤️</h2>
              <p>Deine Meinung zählt mehr als tausend Porsche</p>
            </div>
          </div>
        </div>
      );
    }

    if( user.userToken && showOverview) {
      //TODO Get all Votes from Token and projectID
      //TODO Update Vote Result
      return(
        <div className="we-vote-overview">
          {isLoading ? <div className="we-loading__wrapper"><Loading /></div> : ""}
          <div className="we-start__steps">
            <svg xmlns="http://www.w3.org/2000/svg" width="93.553" height="39.641" viewBox="0 0 93.553 39.641">
              <g id="Gruppe_1" data-name="Gruppe 1" transform="translate(-353.268 72.374)">
                <path id="Pfad_4" data-name="Pfad 4" d="M373.088-72.374a19.821,19.821,0,0,0-19.82,19.821,19.821,19.821,0,0,0,19.82,19.821,19.821,19.821,0,0,0,19.821-19.821A19.821,19.821,0,0,0,373.088-72.374Zm.664,27.918h-3.059a.489.489,0,0,1-.542-.39l-2.516-9.806L365.1-44.847a.48.48,0,0,1-.521.39h-3.059a.451.451,0,0,1-.477-.39l-3.492-14.4c-.065-.238,0-.39.238-.39h2.777a.447.447,0,0,1,.5.39l2.17,9.654,2.56-9.762a.35.35,0,0,1,.368-.282h2.929a.373.373,0,0,1,.39.282l2.538,9.762,2.191-9.654c.065-.238.152-.39.477-.39h2.755c.261,0,.326.152.261.39l-3.492,14.4A.444.444,0,0,1,373.752-44.456Zm14.483-14.209h-7.7l-1.449,6.139h6.941c.13,0,.19.065.156.217l-.107.52a.287.287,0,0,1-.3.217h-6.942l-1.45,6.139h7.7c.151,0,.19.065.156.216l-.131.542c-.013.152-.1.217-.257.217h-8.46c-.152,0-.212-.065-.178-.217l3.533-14.751c.013-.152.1-.217.278-.217h8.439c.152,0,.212.065.178.217l-.131.542A.251.251,0,0,1,388.236-58.666Z" transform="translate(0 0)" fill="#fff"/>
                <text id="VOTE" transform="translate(397.821 -46.452)" fill="#fff" fontSize="20" fontFamily="Crossten-BoldItalic, Crossten" fontWeight="700" fontStyle="italic"><tspan x="0" y="0">VOTE</tspan></text>
              </g>
            </svg>
          </div>
          <button
            className="we-voting__bg-color"
            onClick={() => setIsLight(!isLight)}
          >
            <svg
              id="b93b7ffa-243f-4300-91b3-9e2efd62ad32"
              data-name="Ebene 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 14.014 14.014"
            >
              <g
                id="e424208d-a1a2-4adc-8bf0-80e38eea114b"
                data-name="Gruppe 1"
              >
                <path
                  id="fe4b796b-13ae-4d8f-a0f3-ad0565987783"
                  data-name="Pfad 3"
                  d="M7.006,12.042a5.036,5.036,0,1,0,0-10.071Z"
                  fill="#fff"
                />
                <circle
                  id="ebada532-e5a6-4e53-b2b5-eb3f95cc28d8"
                  data-name="Ellipse 97"
                  cx="7.007"
                  cy="7.007"
                  r="6.507"
                  fill="none"
                  stroke="#fff"
                  stroke-miterlimit="10"
                />
              </g>
            </svg>
          </button>
          <p className="we-vote-overview__description">
            Bist du dir ganz sicher?<br/>
            Hier kannst du noch mal umentscheiden. Gedrückt halten für Vollbild.
          </p>
          <ul className="we-vote-overview__votes">
            {userVotes.length > 0 ? userVotes.map((creative, index) => {
              return <li key={`CREATIVE_${index}`} className={creative.vote.result === "DISLIKE" ? "is--muted" : ""}><button onClick={() => changeUserVote(creative)}>
                  <span className={`we-vote-btn ${creative.vote.result.toLowerCase()}`}>
                    <svg id="Ebene_1" dataname="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.28 141.29"><polygon className="cls-1" points="141.28 0 31.78 25.36 69.44 63.03 65.72 59.3 0 125.02 16.26 141.28 81.98 75.57 115.92 109.51 141.28 0"/></svg>
                  </span>
                  <img
                    src={`data:image/jpeg;base64,${creative.image}`}
                    alt=""
                    title=""
                  />
              </button></li>
            }) : <><li className="we-vote-overview__placeholder"></li><li className="we-vote-overview__placeholder"></li></>}
          </ul>
          <button className="we-btn is--round white" onClick={() => setFinishVote( true )}>Fertig</button>
        </div>
      )
    }

    return (
      <div className={`we-voting${isLight ? " is--light" : ""}`}>
        {isLoading ? <div className="we-loading__wrapper"><Loading /></div> : ""}
        <div className="we-voting__wrapper">
          <div className="we-start__steps">
            <svg xmlns="http://www.w3.org/2000/svg" width="93.553" height="39.641" viewBox="0 0 93.553 39.641">
              <g id="Gruppe_1" data-name="Gruppe 1" transform="translate(-353.268 72.374)">
                <path id="Pfad_4" data-name="Pfad 4" d="M373.088-72.374a19.821,19.821,0,0,0-19.82,19.821,19.821,19.821,0,0,0,19.82,19.821,19.821,19.821,0,0,0,19.821-19.821A19.821,19.821,0,0,0,373.088-72.374Zm.664,27.918h-3.059a.489.489,0,0,1-.542-.39l-2.516-9.806L365.1-44.847a.48.48,0,0,1-.521.39h-3.059a.451.451,0,0,1-.477-.39l-3.492-14.4c-.065-.238,0-.39.238-.39h2.777a.447.447,0,0,1,.5.39l2.17,9.654,2.56-9.762a.35.35,0,0,1,.368-.282h2.929a.373.373,0,0,1,.39.282l2.538,9.762,2.191-9.654c.065-.238.152-.39.477-.39h2.755c.261,0,.326.152.261.39l-3.492,14.4A.444.444,0,0,1,373.752-44.456Zm14.483-14.209h-7.7l-1.449,6.139h6.941c.13,0,.19.065.156.217l-.107.52a.287.287,0,0,1-.3.217h-6.942l-1.45,6.139h7.7c.151,0,.19.065.156.216l-.131.542c-.013.152-.1.217-.257.217h-8.46c-.152,0-.212-.065-.178-.217l3.533-14.751c.013-.152.1-.217.278-.217h8.439c.152,0,.212.065.178.217l-.131.542A.251.251,0,0,1,388.236-58.666Z" transform="translate(0 0)" fill="#fff"/>
                <text id="VOTE" transform="translate(397.821 -46.452)" fill="#fff" fontSize="20" fontFamily="Crossten-BoldItalic, Crossten" fontWeight="700" fontStyle="italic"><tspan x="0" y="0">VOTE</tspan></text>
              </g>
            </svg>
            <p>Vote</p>
          </div>
          <button
            className="we-voting__bg-color"
            onClick={() => setIsLight(!isLight)}
          >
            <svg
              id="b93b7ffa-243f-4300-91b3-9e2efd62ad32"
              data-name="Ebene 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 14.014 14.014"
            >
              <g
                id="e424208d-a1a2-4adc-8bf0-80e38eea114b"
                data-name="Gruppe 1"
              >
                <path
                  id="fe4b796b-13ae-4d8f-a0f3-ad0565987783"
                  data-name="Pfad 3"
                  d="M7.006,12.042a5.036,5.036,0,1,0,0-10.071Z"
                  fill="#fff"
                />
                <circle
                  id="ebada532-e5a6-4e53-b2b5-eb3f95cc28d8"
                  data-name="Ellipse 97"
                  cx="7.007"
                  cy="7.007"
                  r="6.507"
                  fill="none"
                  stroke="#fff"
                  stroke-miterlimit="10"
                />
              </g>
            </svg>
          </button>
          <p className="we-voting__progress">
            {currentCreative + 1} / {project.creatives.length}
          </p>
          <img
            className="we-voting__image"
            src={`data:image/jpeg;base64,${creative.image}`}
            alt=""
            title=""
          />
          <div className="we-voting__actions">
            <button
              className="we-vote-btn dislike"
              onClick={() => voteCreative(creative.id, "DISLIKE")}
              disabled={isDisabled}
            >
              <svg id="Ebene_1" dataname="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.28 141.29"><polygon className="cls-1" points="141.28 0 31.78 25.36 69.44 63.03 65.72 59.3 0 125.02 16.26 141.28 81.98 75.57 115.92 109.51 141.28 0"/></svg>
            </button>
            <button
              className="we-vote-btn like"
              onClick={() => voteCreative(creative.id, "LIKE")}
              disabled={isDisabled}
            >
              <svg id="Ebene_1" dataname="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.28 141.29"><polygon className="cls-1" points="141.28 0 31.78 25.36 69.44 63.03 65.72 59.3 0 125.02 16.26 141.28 81.98 75.57 115.92 109.51 141.28 0"/></svg>
            </button>
          </div>
        </div>
      </div>
    );
  }
}