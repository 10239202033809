export default function AGB() {
    return(
        <>
        <h3>Allgemeine Geschäftsbedingungen</h3>
                <p>
                	von<br />
                	W&S Epic GmbH<br />
					Schulze-Delitzsch-Str. 35<br />
					30938 Burgwedel
                </p>
                <p>
                	- im Folgenden: W&S Epic GmbH -
				</p>
				
				<h3>Teil 1 -  Allgemeine Bestimmungen</h3>
				<ol>
					<li class="head">
						Allgemeines
						<ol>
							<li>
								Diese Allgemeinen Geschäftsbedingungen (AGB) gelten für alle Verträge, die zwischen W&S Epic GmbH und dem Kunden geschlossen werden.
							</li>
							<li>
								W&S Epic GmbH bietet dem Kunden verschiedene Agenturleistungen an. Der spezifische Leistungsumfang ist Gegenstand von Individualvereinbarungen zwischen W&S Epic GmbH und dem Kunden. 
							</li>
							<li>
								W&S Epic GmbH schließt keine Verträge mit Verbrauchern bzw. Privatpersonen. 
							</li>
							<li>
								W&S Epic GmbH ist berechtigt, in eigenem Namen und auf eigene Rechnung die erforderlichen Leistungen an Subunternehmer zu vergeben, die ihrerseits ebenfalls Subunternehmer einsetzen dürfen. W&S Epic GmbH bleibt hierbei alleiniger Vertragspartner des Kunden. Der Einsatz von Subunternehmern erfolgt nicht, sofern für W&S Epic GmbH ersichtlich ist, dass deren Einsatz berechtigten Interessen des Kunden zuwiderläuft.
							</li>
							<li>
								Von diesen Geschäftsbedingungen abweichende AGB, die durch den Kunden verwendet werden, erkennt W&S Epic GmbH – vorbehaltlich einer ausdrücklichen Zustimmung – nicht an.
							</li>
						</ol>
					</li>
					<li class="head">
						Mitwirkungspflichten des Kunden
						<ol>
							<li>
								Sofern der Kunde W&S Epic GmbH Texte, Bilder oder sonstige Inhalte zur Verfügung stellt, hat er dafür zu sorgen, dass diese Inhalte nicht gegen die Rechte Dritter (z.B. Urheberrechte) oder sonstige Rechtsnormen verstoßen. Es wird in diesem Zusammenhang darauf hingewiesen, dass W&S Epic GmbH von Rechts wegen nicht berechtigt ist, Rechtsberatungsleistungen gegenüber dem Kunden zu erbringen. W&S Epic GmbH ist insbesondere nicht verpflichtet und rechtlich nicht in der Lage, das Geschäftsmodell des Kunden und/oder die vom Kunden selbst erstellten oder erworbenen Werke (Layouts, Grafiken, Texte etc.) auf ihre Vereinbarkeit mit dem geltenden Recht zu prüfen. W&S Epic GmbH wird insbesondere keine Markenrecherchen oder sonstige Schutzrechtskollisionsprüfungen in Bezug auf die vom Kunden zur Verfügung gestellten Werke vornehmen. Soweit der Kunde bestimmte Weisungen bzgl. des herzustellenden Werks erteilt, haftet er hierfür selbst.
							</li>
							<li>
								Der Kunde ist verpflichtet, die von ihm zum Zwecke der Auftragserfüllung zur Verfügung zu stellenden Informationen, Daten, Werke (z.B. die Daten für das Impressum, Grafiken etc.) und Zugänge vollständig und korrekt mitzuteilen. Er hat ferner dafür Sorge zu tragen, dass die von ihm erteilten Weisungen mit dem geltenden Recht in Einklang stehen.
							</li>
							<li>
								Der Kunde ist – vorbehaltlich abweichender Individualvereinbarungen – für die Beschaffung des Materials zur Erbringung der Agenturleistungen (z.B. Grafiken, Videos) selbst verantwortlich und stellt diese W&S Epic GmbH rechtzeitig zur Verfügung. Stellt der Kunde diese nicht zur Verfügung und macht er auch keine weitergehenden Vorgaben, so kann W&S Epic GmbH nach eigener Wahl unter Beachtung der urheberrechtlichen Kennzeichnungsvorgaben Bildmaterial gängiger Anbieter (z.B. Stockfoto-Dienstleister) verwenden oder die entsprechenden Teile der Webseite mit einem Platzhalter versehen.
							</li>
							<li>
								Sofern für einzelne Auftragsbestandteile der Abschluss eines Auftragsverarbeitungsvertrags nach Art. 28 DSGVO erforderlich ist, verpflichten sich beide Vertragsparteien, einen solchen – von W&S Epic GmbH zu stellenden – Vertrag vor Beginn der Leistungserbringung abzuschließen.
							</li>
							<li>
								Für Verzögerungen und Verspätungen bei der Umsetzung von Projekten, die durch eine verspätete (notwendige) Mit- bzw. Zuarbeit des Kunden entstehen, ist W&S Epic GmbH gegenüber dem Kunden in keinerlei Hinsicht verantwortlich; die Vorschriften unter der Überschrift „Haftung/Freistellung“ bleiben hiervon unberührt.
							</li>
							<li>
								Kommt der Kunde seinen Verpflichtungen aus dieser Ziffer nicht nach, kann W&S Epic GmbH dem Kunden den hierdurch entstehenden Zusatzaufwand (z.B. Kosten für Stockfotos und Zeitaufwand für deren Suche) in Rechnung stellen.
							</li>
						</ol>
					</li>
				</ol>
				
				<h3>Teil 2 -  Onlineauftritte und Technik</h3>
				<ol>
					<li class="head">
						Webseiten- und Shoperstellung (agil)
						<ol>
							<li>
								Sofern keine abweichenden Individualvereinbarungen getroffen wurden, erfolgt die Erstellung von neuen oder die Erweiterung bestehender Webseiten/Shops oder Webkomponenten/ Shopkomponenten (nachfolgend „Webseitenerstellung“) auf Grundlage agiler Methoden. Die übrigen Regelungen dieser AGB bleiben unberührt.
							</li>
							<li>
								Gegenstand von Webseiten-Erstellungsverträgen zwischen W&S Epic GmbH und dem Kunden ist grundsätzlich die Entwicklung neuer Webseiten oder die Erweiterung bestehender Webseiten (z.B. Einbinden neuer Schnittstellen oder Programmierung neuer Online-Anwendungen) unter Beachtung der technischen und/oder gestalterischen Vorgaben des Kunden. Zwischen den Parteien geschlossene Webseiten-Erstellungsverträge sind Werkverträge im Sinne von §§ 631 ff. BGB. 
							</li>
							<li>
								Die im Einzelnen vereinbarten Leistungen ergeben sich aus dem zwischen W&S Epic GmbH und dem Kunden individuell abgeschlossenen Vertrag. Hierzu stellt der Kunde bei W&S Epic GmbH zunächst eine Anfrage mit einer möglichst genauen Beschreibung der von ihm gewünschten Webseiten-Inhalte (gestalterische Inhalte wie Bilder, Layouts, Logos, Schriften u.Ä. sind vorbehaltlich abweichender Vereinbarungen vom Kunden festzulegen und zur Verfügung zu stellen). Diese Anfrage stellt eine Aufforderung zur Abgabe eines Angebots durch W&S Epic GmbH dar. W&S Epic GmbH wird die in der Anfrage beschriebenen Vorstellungen des Kunden nach bestem Wissen und Gewissen auf Vollständigkeit, Geeignetheit (mit Ausnahme der rechtlichen Geeignetheit, insbesondere hinsichtlich der Rechte von Dritten), Eindeutigkeit, Realisierbarkeit und Widerspruchsfreiheit prüfen und auf Grundlage der aus der Kundenanfrage hervorgehenden Wünsche ein Angebot erstellen. Erst durch die Annahme des Angebots durch den Kunden kommt ein Vertrag zwischen W&S Epic GmbH und dem Kunden zustande.
							</li>
							<li>
								Der Kunde kann jederzeit Kundenwünsche einbringen, soweit diese vom ursprünglich vereinbarten Leistungsumfang gedeckt sind. Derartige Anpassungen werden Bestandteil des ursprünglichen Vertrags, wenn beide Vertragsparteien in Textform (z.B. per E-Mail) zustimmen. Im Übrigen ist W&S Epic GmbH nur zur Herstellung der im Vertrag aufgelisteten Funktionen/Positionen bzw. zur Erbringung der vereinbarten Dienstleistung verpflichtet. Darüber hinausgehende Leistungen müssen gesondert vereinbart und vergütet werden.
							</li>
							<li>
								Sobald die Webseite fertiggestellt wurde, wird W&S Epic GmbH den Kunden zur Abnahme der Webseite auffordern. 
							</li>
							<li>
								Voraussetzung für die Tätigkeit von W&S Epic GmbH ist, dass sämtliche vom Kunden zu stellenden und für die Umsetzung des Projekts erforderlichen Daten (z.B. Texte, Vorlagen, Grafiken, Schriften) und/oder Systemumgebungen W&S Epic GmbH rechtzeitig und in geeigneter Form zur Verfügung gestellt werden. Für Verzögerungen und Verspätungen bei der Umsetzung von Projekten, die durch eine verspätete (notwendige) Mit- bzw. Zuarbeit des Kunden entstehen, ist W&S Epic GmbH gegenüber dem Kunden in keinerlei Hinsicht verantwortlich. 
							</li>
							<li>
								Die Prüfung oder Beschaffung von Rechten, die Beschaffung und/oder Einbindung von Plugins und/oder Tools (z.B. Statistik) oder Zertifikaten (z.B. SSL / TLS) sind von W&S Epic GmbH nur dann geschuldet, soweit dies individualvertraglich ausdrücklich vereinbart ist. Ein Anspruch auf die Herausgabe von Grafiken, Quellcodes, (Entwicklungs-)Dokumentationen, Handbücher und sonstiger Zusatzdokumentation besteht – vorbehaltlich abweichender ausdrücklicher Individualvereinbarungen – nicht.
							</li>
							<li>
								Soweit nicht anders vereinbart sind die erstellten Webseiten für die Browser Chrome, Safari, Firefox und Edge in ihrer jeweils aktuellen Fassung optimiert (jeweils die letzten zwei Versionen des Browsers). Suchmaschinenoptimierung (SEO) wird nur geschuldet, wenn sie ausdrücklich vereinbart wurde. Eine Optimierung für Mobilgeräte ist ebenfalls nur dann geschuldet, wenn dies ausdrücklich vereinbart wurde.
							</li>
							<li>
								W&S Epic GmbH ist nicht berechtigt und nicht verpflichtet, den Kunden zu wettbewerbsrechtlichen, verbraucherrechtlichen, kennzeichnungsrechtlichen oder sonstigen rechtlichen Fragen im Sinne des Rechtsdienstleistungsgesetzes zu beraten. Es obliegt daher dem Kunden, sich über die für seinen Shop geltenden wettbewerbsrechtlichen, verbraucherrechtlichen oder kennzeichnungsrechtlichen Bestimmungen zu informieren und den Shop gegebenenfalls durch einen spezialisierten Rechtsanwalt prüfen zu lassen. 
							</li>
							<li>
								Nach Fertigstellung der Webseiten und/oder einzelner Teile hiervon kann W&S Epic GmbH dem Kunden Wartungs- und Pflegeleistungen in Bezug auf die Webseiten anbieten. Jedoch ist weder W&S Epic GmbH zu einem solchen Angebot verpflichtet, noch muss der Kunde die weitergehenden Leistungsangebote von W&S Epic GmbH in Anspruch nehmen. Entsprechende Vereinbarungen sind ausschließlich Gegenstand von Individualvereinbarungen. Werden keine zusätzlichen Wartungs- und Pflegeleistungen zwischen den Parteien vereinbart, ist nach Abnahme allein der Kunde für die technische Instandhaltung und Aktualität der Webseiten verantwortlich. W&S Epic GmbH haftet gegenüber dem Kunden nicht für eventuelle Sicherheitslücken, die durch die Verwendung veralteter Software von Dritten zu rechtswidrigen Zwecken ausgenutzt werden (Hacking).
							</li>
						</ol>
					</li>
					<li class="head">
						Wartung und Betreuung von Webseiten / Shops
						<ol>
							<li>
								Nach Fertigstellung der Webseiten und/oder einzelner Teile hiervon kann W&S Epic GmbH dem Kunden Wartungs- und Betreuungsleistungen in Bezug auf die Webseiten anbieten (nachfolgend „Wartungsverträge“). W&S Epic GmbH kann auch die Wartung von Drittwebseiten anbieten. Jedoch ist weder W&S Epic GmbH zu einem solchen Angebot verpflichtet, noch muss der Kunde die weitergehenden Leistungsangebote von W&S Epic GmbH in Anspruch nehmen. Entsprechende Vereinbarungen sind ausschließlich Gegenstand von Individualabsprachen.
							</li>
							<li>
								Inhalt der Wartungsverträge ist die Beseitigung von Funktionsstörungen sowie die anlassbezogene Aktualisierung der Webseite für gängige Webbrowser in ihrer jeweils aktuellen Version. Weitergehende Leistungen, wie z.B. regelmäßige Wartungen, können ggf. individualvertraglich vereinbart werden.
							</li>
							<li>
								Voraussetzung für die Wartung ist, dass die zu wartenden Inhalte mit den Systemen von W&S Epic GmbH kompatibel sind. Die Kompatibilität kann insbesondere durch veraltete Komponenten der zu wartenden Inhalte oder durch eigenmächtige Änderungen von Seiten des Kunden beeinträchtigt werden. Sollte die Kompatibilität nicht gewährleistet sein, muss der Kunde diese selbstständig herstellen (z.B. durch entsprechende Updates) oder W&S Epic GmbH gesondert mit der Herstellung der Kompatibilität beauftragen. 
							</li>
							<li>
								W&S Epic GmbH haftet nicht für Funktionsstörungen und Inkompatibilitäten, die durch eigenmächtige Änderungen des Kunden verursacht wurden oder auf sonstigen Fehlern beruhen, die nicht im Verantwortungsbereich von W&S Epic GmbH liegen; die Vorschriften unter „Haftung / Freistellung“ bleiben hiervon unberührt.
							</li>
							<li>
								Die Wartung umfasst, vorbehaltlich abweichender Vereinbarungen, nur die technische, nicht jedoch die inhaltliche Aktualisierung der Webseite. W&S Epic GmbH schuldet insbesondere nicht die Aktualisierung des Impressums oder der Datenschutzerklärung.
							</li>
						</ol>
					</li>
				</ol>
				
				<h3>Teil 3 - Erstellung und Gestaltung von Content</h3>
				<ol>
					<li class="head">
						Gestaltung von Printprodukten
						<ol>
							<li>
								Gegenstand von Designverträgen im Printbereich zwischen W&S Epic GmbH und dem Kunden ist grundsätzlich die Entwicklung von Printprodukten nach den gestalterischen Vorgaben des Kunden (z.B. Ausgestaltung von Bannern, Postgrafiken, Plakaten, Schildern, Flyern, Roll-Ups, KFZ- oder Schaufenster-Beklebungen, Textilien oder Logo-Entwürfen). Zwischen den Parteien geschlossene Designverträge sind Werkverträge im Sinne von § 631 ff. BGB. Ein abweichender Leistungsumfang kann zwischen den Parteien individualvertraglich vereinbart werden.
							</li>
							<li>
								Die im Einzelnen vereinbarten Leistungen ergeben sich aus dem zwischen W&S Epic GmbH und dem Kunden individuell geschlossenen Vertrag. Hierzu stellt der Kunde bei W&S Epic GmbH zunächst eine Anfrage mit einer möglichst genauen Beschreibung der von ihm gewünschten Design-Leistungen. Diese Anfrage stellt eine Aufforderung zur Abgabe eines Angebots durch W&S Epic GmbH dar. W&S Epic GmbH wird die in der Anfrage beschriebenen Vorstellungen des Kunden nach bestem Wissen und Gewissen auf Vollständigkeit, Geeignetheit (mit Ausnahme der rechtlichen Geeignetheit, insbesondere in Bezug auf die Rechte von Dritten), Eindeutigkeit, Realisierbarkeit und Widerspruchsfreiheit prüfen und auf Grundlage der aus der Kundenanfrage hervorgehenden Wünsche ein Angebot erstellen. Erst durch die Annahme des Angebots durch den Kunden kommt ein Vertrag zwischen W&S Epic GmbH und dem Kunden zustande.
							</li>
							<li>
								Nach Abschluss des Vertrages werden die Anforderungen des Kunden bei Bedarf in einem weiteren Briefing besprochen und die Vorgaben konkretisiert. Zu diesem Zeitpunkt können Kundenwünsche eingebracht werden, sofern sie vom ursprünglich vereinbarten Leistungsumfang gedeckt sind. Sofern erforderlich besteht die Möglichkeit eines Rebriefings vor Fertigung des Leistungsgegenstands. Anpassungen werden Bestandteil des ursprünglichen Vertrags, wenn beide Vertragsparteien in Textform (z.B. per E-Mail) zustimmen. Im Übrigen ist W&S Epic GmbH nur zur Herstellung der im Vertrag aufgelisteten Positionen verpflichtet. Darüberhinausgehende Leistungen müssen gesondert vereinbart und vergütet werden.
							</li>
							<li>
								Soweit nichts anderes vereinbart, steht dem Kunden das Recht auf zwei Korrekturschleifen zu. Reklamationen hinsichtlich der künstlerischen Gestaltung sind nach Durchführung der vereinbarten Korrekturschleifen grundsätzlich ausgeschlossen. Wünscht der Kunde darüber hinaus weitere Änderungen, so hat er die Mehrkosten zu tragen.
							</li>
							<li>
								Voraussetzung für die Tätigkeit von W&S Epic GmbH ist, dass der Kunde sämtliche für die Umsetzung des Projekts erforderlichen Daten (Texte, Vorlagen, Grafiken etc.) W&S Epic GmbH vor Auftragsbeginn vollständig und in geeigneter Form zur Verfügung stellt. Für Verzögerungen und Verspätungen bei der Umsetzung von Projekten, die durch eine verspätete (notwendige) Mit- bzw. Zuarbeit des Kunden entstehen, ist W&S Epic GmbH gegenüber dem Kunden in keinerlei Hinsicht verantwortlich. Kommt der Kunde dieser Verpflichtung nicht nach, kann W&S Epic GmbH dem Kunden den hierdurch entstehenden Zeitaufwand in Rechnung stellen.
							</li>
							<li>
								Die Vergütung ist Gegenstand einer individualvertraglichen Vereinbarung zwischen den Parteien. Im Übrigen finden die gesetzlichen Vorschriften Anwendung.
							</li>
							<li>
								Soweit vertraglich nicht anders vereinbart und vom Vertragszweck nicht anders zu erwarten, schuldet W&S Epic GmbH bei der Erstellung von Printprodukten neben den vertraglich vereinbarten Leistungsgegenständen nur die Übergabe einer Druckdatei (z.B. PDF, JPG oder PNG). Der Kunde hat keinen Anspruch auf Herausgabe einer bearbeitbaren Datei (z.B. offene Dateien aus Grafikprogrammen).
							</li>
						</ol>
					</li>
					<li class="head">
						Abwicklung von Printaufträgen
						<ol>
							<li>
								W&S Epic GmbH bietet dem Kunden die Abwicklung von Aufträgen zur Erstellung von Printprodukten (Flyer, Broschüren, Plakate, Kataloge u.Ä.) an. W&S Epic GmbH übernimmt sämtliche hierfür vereinbarten Handlungen, z.B. die Kommunikation mit dem jeweiligen den Druck ausführenden Dienstleister (Druckdienstleister). Je nach Vereinbarung bietet W&S Epic GmbH die Leistungen als Direktgeschäft oder als Vermittlungsgeschäft an.
							</li>
							<li>
								Vereinbaren die Parteien ein Direktgeschäft, druckt W&S Epic GmbH die in Auftrag gegebenen Printprodukte selbst oder beauftragt in eigenem Namen und auf eigene Rechnung einen Druckdienstleister Vertragspartner des Kunden ist in diesem Fall ausschließlich W&S Epic GmbH. Zwischen dem Kunden und dem Druckdienstleister entsteht keine Vertragsbeziehung. W&S Epic GmbH stellt dem Kunden die Printprodukte direkt in Rechnung. Der Kunde nimmt die Printprodukte gegenüber W&S Epic GmbH ab.
							</li>
							<li>
								Vereinbaren die Parteien ein Vermittlungsgeschäft, schließt W&S Epic GmbH den Vertrag für die Erstellung der Printprodukte mit dem Druckdienstleister im Namen und auf Rechnung des Kunden ab oder vermittelt einen solchen Vertrag. W&S Epic GmbH tritt gegenüber dem Druckdienstleister als reiner Vermittler auf. Die Vertragsbeziehung entsteht allein zwischen dem Kunden und dem Druckdienstleister. W&S Epic GmbH ist an diesem Vertrag nicht beteiligt. W&S Epic GmbH informiert den Kunden über alle wesentlichen Schritte und stimmt sich hinsichtlich der Details zum Vertragsinhalt und -abschluss (insbesondere zu Art, Preisen und Mengen) mit dem Kunden ab und ist an dessen Weisungen gebunden. Es gelten die jeweiligen Preis- und/oder Geschäftsbedingungen des Druckdienstleisters. Der Kunde bezahlt die Leistungen direkt gegenüber dem Druckdienstleister. Die Abnahme der Printprodukte erfolgt gegenüber dem Druckdienstleister. Es obliegt dem Kunden, die fertig gestellten Printprodukte auf ihre Mangelfreiheit hin zu überprüfen. W&S Epic GmbH haftet nicht für die vertragsgemäße Erzeugung der Printprodukte durch den Druckdienstleister, insbesondere nicht für deren Inhalt, Bestand, die Güte und/oder Beschaffenheit. W&S Epic GmbH stellt im Streitfall dem Kunden – soweit rechtlich zulässig – alle notwendigen Informationen zu Verfügung. Die darüberhinausgehende Unterstützung der Geltendmachung von Mängelgewährleistungs- oder sonstigen Ansprüchen ist seitens W&S Epic GmbH nicht geschuldet. Die Vorschriften unter „Haftung / Freistellung“ bleiben hiervon unberührt.
							</li>
							<li>
								Der Kunde ist verpflichtet, die zu übermittelnden Druckdaten vor Übermittlung an den Druckdienstleister sorgfältig auf inhaltliche und technische Richtigkeit und Vollständigkeit zu prüfen. Vorbehaltlich abweichender Vereinbarungen, überprüft W&S Epic GmbH die Druckdaten nicht auf inhaltliche oder technische Richtigkeit. Der Druck der in Auftrag gegebenen Printerzeugnisse erfolgt erst dann, wenn der Kunde die finale Druckfreigabe erteilt hat.
							</li>
							<li>
								Sofern ein bestimmtes Übermittlungsformat erforderlich ist (z.B. PDF, Indesign), wird der Kunde die Druckdaten in diesem Format übermitteln.
							</li>
						</ol>
					</li>
					<li class="head">
						Video und Fotografie
						<ol>
							<li>
								W&S Epic GmbH erstellt für seine Kunden professionelle Videos und Fotografien. Die im Einzelnen vereinbarten Leistungen ergeben sich aus dem zwischen W&S Epic GmbH und dem Kunden individuell geschlossenen Vertrag. 
							</li>
							<li>
								Der Kunde stellt bei W&S Epic GmbH zunächst eine Anfrage mit einer möglichst genauen Beschreibung der von ihm gewünschten Leistungen. Diese Anfrage stellt eine Aufforderung zur Abgabe eines Angebots durch W&S Epic GmbH dar. W&S Epic GmbH wird die in der Anfrage beschriebenen Vorstellungen des Kunden nach bestem Wissen und Gewissen auf Vollständigkeit, Geeignetheit (mit Ausnahme der rechtlichen Geeignetheit, insbesondere auf die Rechte von Dritten), Eindeutigkeit, Realisierbarkeit und Widerspruchsfreiheit prüfen und auf Grundlage der aus der Kundenanfrage hervorgehenden Wünsche ein Angebot erstellen. Erst durch die Annahme des Angebots durch den Kunden kommt ein Vertrag zwischen W&S Epic GmbH und dem Kunden zustande.
							</li>
							<li>
								Die Vorgaben des Kunden werden nach bestem Wissen und Gewissen berücksichtigt. Die Vertragsparteien erkennen an, dass es sich bei der Erstellung von Videos und Fotografien um eine kreative Leistung handelt, die ein hohes Maß an künstlerischer Freiheit erfordert. W&S Epic GmbH schuldet daher ausschließlich die Erstellung eines Werks, das nach dessen eigener Erfahrung und Einschätzung den Wünschen des Kunden entspricht. Reklamationen hinsichtlich der künstlerischen Gestaltung sind grundsätzlich ausgeschlossen.
							</li>
							<li>
								Soweit nicht anders vereinbart, steht dem Kunden das Recht auf zwei Korrekturschleifen hinsichtlich der Bildbearbeitung (z.B. durch Filter und Effekte) der erstellten Fotografien zu; eine Neuerstellung der Fotografien ist jedoch ausgeschlossen. Reklamationen hinsichtlich der künstlerischen Gestaltung sind grundsätzlich ausgeschlossen. Wünscht der Kunde darüber hinaus weitere Änderungen, so hat er die Mehrkosten zu tragen
							</li>
							<li>
								Sofern der Kunde für die Erstellung von Videos oder Fotografien Personen zur Verfügung stellt (z.B. dessen Mitarbeiter oder professionelle Models), ist er allein dafür verantwortlich, dass die betreffenden Personen in die Verwendung der Aufnahmen eingewilligt haben. Er ist insbesondere für den Abschluss geeigneter Model-Release-Verträge und die Einholung datenschutzkonformer Mitarbeitereinwilligungen verantwortlich. 
							</li>
							<li>
								Sobald der vereinbarte Leistungsgegenstand fertiggestellt wurde, wird W&S Epic GmbH den Kunden zur Abnahme des Werks auffordern.
							</li>
							<li>
								Soweit nicht anders individualvertraglich vereinbart, kann W&S Epic GmbH verlangen, dass auf den erstellten Werken ein geeigneter Urheberrechtsvermerk an einer angemessenen Stelle platziert wird.
							</li>
							<li>
								Soweit vertraglich nicht anders vereinbart und vom Vertragszweck nicht anders zu erwarten, erhält der Kunde grundsätzlich nur für den jeweiligen Einsatzzweck fertig bearbeitete Aufnahmen. Einen Anspruch auf Herausgabe der Rohdaten bzw. bearbeitbare Dateien (RAW-Dateien, offene Dateien aus Bildprogrammen o. Ä.) hat der Kunde nicht.
							</li>
							<li>
								Sofern W&S Epic GmbH die hier genannten Leistungen nicht selbst durchführen kann oder möchte, kann W&S Epic GmbH dem Kunden geeignete Dienstleister hierfür vermitteln (Vermittlungsgeschäft). Vereinbaren die Parteien ein Vermittlungsgeschäft, schließt W&S Epic GmbH den Vertrag für die Erstellung der Videos / Fotografien mit dem Drittdienstleister im Namen und auf Rechnung des Kunden ab oder vermittelt einen solchen Vertrag. W&S Epic GmbH tritt gegenüber dem Drittdienstleister als reiner Vermittler auf. Die Vertragsbeziehung entsteht allein zwischen dem Kunden und dem Drittdienstleister. W&S Epic GmbH ist an diesem Vertrag nicht beteiligt. W&S Epic GmbH informiert den Kunden über alle wesentlichen Schritte und stimmt sich hinsichtlich der Details zum Vertragsinhalt und -abschluss (insbesondere zu Art und Preisen) mit dem Kunden ab und ist an dessen Weisungen gebunden. Es gelten die jeweiligen Preis- und/oder Geschäftsbedingungen des Drittdienstleisters. Der Kunde bezahlt die Leistungen direkt gegenüber dem Drittdienstleister. Die Abnahme der Leistungen erfolgt gegenüber dem Drittdienstleister. Es obliegt dem Kunden, die fertig gestellten Video / Fotografien auf ihre Mangelfreiheit hin zu überprüfen. W&S Epic GmbH haftet nicht für die vertragsgemäße Erzeugung der Leistungen durch den Drittdienstleister. W&S Epic GmbH stellt im Streitfall dem Kunden – soweit rechtlich zulässig – alle notwendigen Informationen zu Verfügung. Die darüberhinausgehende Unterstützung der Geltendmachung von Mängelgewährleistungs- oder sonstigen Ansprüchen ist seitens W&S Epic GmbH nicht geschuldet. Die Vorschriften unter „Haftung / Freistellung“ bleiben hiervon unberührt.  
							</li>
						</ol>
					</li>
					<li class="head">
						Erstellung von Texten / Copywriting
						<ol>
							<li>
								W&S Epic GmbH erstellt für den Kunden u.a. Texte (z.B. Pressemeldungen, Beiträge für Webseiten, Werbetexte etc.). Die Inhalte dieser Texte werden individualvertraglich festgelegt.
							</li>
							<li>
								Sobald die vereinbarten Texte fertiggestellt wurden, wird W&S Epic GmbH sie dem Kunden zur Freigabe und Abnahme übermitteln. Soweit nicht anders vereinbart, steht dem Kunden das Recht auf zwei Korrekturschleifen zu. Reklamationen hinsichtlich der stilistischen Gestaltung oder die Einbindung neuer Informationen in den Text sind nach der zweiten Änderungsschleife grundsätzlich ausgeschlossen. Wünscht der Kunde darüber hinaus weitere Änderungen, so hat er die Mehrkosten zu tragen.
							</li>
							<li>
								Sofern W&S Epic GmbH mit der Veröffentlichung beauftragt wurde, erfolgt die Veröffentlichung der Texte vorbehaltlich abweichender Vereinbarungen erst nach Freigabe durch den Kunden; die Freigabe stellt zugleich die Abnahme der Texte dar. Bei Pressemeldungen wird nach erfolgter Freigabe ferner ein Distributionsdatum festgelegt, an dem diese an die Medien übermittelt werden soll. Sofern der Kunde die Texte selbst veröffentlicht oder veröffentlichen soll, hat er die Texte vorab abzunehmen. Sofern der Kunde die Texte vor Abnahme veröffentlicht, gilt die Veröffentlichung als Abnahme.
							</li>
							<li>
								Für Fehler, die nach der Freigabe / Abnahme entdeckt werden, haftet W&S Epic GmbH ausschließlich nach Maßgabe der Vorschriften unter der Überschrift „Haftung / Freistellung“.
							</li>
						</ol>
					</li>
					<li class="head">
						Gestaltung und Konzeption von Grafiken und Logos (Designs)
						<ol>
							<li>
								W&S Epic GmbH übernimmt nach Vereinbarung mit dem Kunden die Konzeption und Gestaltung von Grafiken und/oder Logos (im Folgenden „Designs“). 
							</li>
							<li>
								Hierzu stellt der Kunde bei W&S Epic GmbH zunächst eine Anfrage mit einer möglichst genauen Beschreibung der von ihm gewünschten Designs. Diese Anfrage stellt eine Aufforderung zur Abgabe eines Angebots durch W&S Epic GmbH dar. W&S Epic GmbH wird die in der Anfrage beschriebenen Vorstellungen des Kunden nach bestem Wissen und Gewissen auf Vollständigkeit, Geeignetheit (mit Ausnahme der rechtlichen Geeignetheit, insbesondere hinsichtlich der Rechte von Dritten), Eindeutigkeit, Realisierbarkeit und Widerspruchsfreiheit prüfen und auf Grundlage der aus der Kundenanfrage hervorgehenden Wünsche ein Angebot erstellen. Erst durch die Annahme des Angebots durch den Kunden kommt ein Vertrag zwischen W&S Epic GmbH und dem Kunden zustande.
							</li>
							<li>
								Voraussetzung für die Tätigkeit von W&S Epic GmbH ist, dass der Kunde sämtliche für die Umsetzung des Projekts erforderliche Daten (Farbdefinition etc.) W&S Epic GmbH vor Auftragsbeginn vollständig in geeigneter Form zur Verfügung stellt. Kommt der Kunde dieser Verpflichtung nicht nach, kann W&S Epic GmbH dem Kunden den hierdurch entstehenden Zeitaufwand in Rechnung stellen.
							</li>
							<li>
								Soweit nichts anderes vereinbart, steht dem Kunden betreffend die einzelnen Designs das Recht auf je zwei Korrekturschleifen zu. Nach der Durchführung dieser Korrekturschleifen werden Anpassungswünsche und Reklamationen (insbesondere hinsichtlich der künstlerischen Gestaltung) nicht mehr berücksichtigt. Wünscht der Kunde nach Durchführung der vereinbarten Korrekturschleifen weitere Änderungen, kann W&S Epic GmbH dem Kunden diese gegen ein zusätzlich zu vereinbarendem Entgelt erstellen.
							</li>
							<li>
								Sobald das vereinbarte Design fertiggestellt wurde, wird W&S Epic GmbH den Kunden zur Abnahme des Werks auffordern. Die Designs werden dem Kunden in einem gängigen Dateiformat zugesandt.
							</li>
							<li>
								W&S Epic GmbH überprüft die finalen Designs ausdrücklich weder auf rechtliche Zulässigkeit (insbesondere Marken- und/oder Wettbewerbsrecht) oder auf Verletzung von sonstigen Kennzeichen- und / oder Schutzrechte (bspw. Marken, Geschmackmuster, Patente usw.) noch auf die Eintragungsfähigkeit der Designs z.B. in amtlichen Registern. 
							</li>
							<li>
								W&S Epic GmbH räumt dem Kunden die für den jeweiligen Zweck erforderlichen Nutzungsrechte an den Designs ein. Vorbehaltlich abweichender Regelungen wird bei der Erstellung von Logos ein zeitlich, örtlich und inhaltlich unbeschränktes, ausschließliches Nutzungsrecht eingeräumt; einzelne grafische Elemente der Logos dürfen jedoch für die Erstellung anderer Werke verwendet werden, solange hierdurch keine Verwechslungsgefahr zum erstellten Logo entsteht. Bei allen übrigen Designs wird vorbehaltlich abweichender Individualvereinbarungen ein einfaches Nutzungsrecht eingeräumt. Eine Übertragung der Nutzungsrechte durch den Kunden an Dritte bedarf einer individualvertraglichen Vereinbarung mit W&S Epic GmbH. Die innerhalb der Korrekturschleife präsentierten Entwürfe dürfen ohne ausdrückliche Einwilligung von W&S Epic GmbH durch den Kunden weder im Original noch verändert genutzt, vervielfältigt oder an Dritte weitergegeben werden.
							</li>
							<li>
								Die Nutzungsrechte gehen erst nach vollständiger Bezahlung der Vergütung auf den Kunden über.
							</li>
							<li>
								Soweit nicht anders individualvertraglich vereinbart, kann W&S Epic GmbH verlangen, dass auf den erstellten Werken ein geeigneter Urheberrechtsvermerk an einer angemessenen Stelle platziert wird.
							</li>
						</ol>
					</li>
					<li class="head">
						Corporate Identity – Konzeption/Entwicklung
						<ol>
							<li>
								W&S Epic GmbH übernimmt nach Vereinbarung mit dem Kunden die Konzeption/Entwicklung der „Corporate-Identity“ (z.B. Neuentwicklung oder Überarbeitung von Unternehmenskonzepten im Bereich Außenauftritt, Marken, Corporate Culture, Corporate Behavior, Corporate Communication etc.). Hierzu stellt der Kunde bei W&S Epic GmbH zunächst eine Anfrage mit einer möglichst genauen Beschreibung des von ihm gewünschten unternehmerischen Erscheinungsbildes. Diese Anfrage stellt eine Aufforderung zur Abgabe eines Angebots durch W&S Epic GmbH dar. W&S Epic GmbH wird die in der Anfrage beschriebenen Vorstellungen des Kunden nach bestem Wissen und Gewissen auf Vollständigkeit, Geeignetheit (mit Ausnahme der rechtlichen Geeignetheit, insbesondere auf die Rechte von Dritten), Eindeutigkeit, Realisierbarkeit und Widerspruchsfreiheit prüfen und auf Grundlage der aus der Kundenanfrage hervorgehenden Wünsche ein Angebot erstellen. Erst durch die Annahme des Angebots durch den Kunden kommt ein Vertrag zwischen W&S Epic GmbH und dem Kunden zustande.
							</li>
							<li>
								Nach Abschluss des Vertrages werden die Anforderungen des Kunden bei Bedarf in einem weiteren Briefing besprochen und die Vorgaben konkretisiert. Zu diesem Zeitpunkt können Kundenwünsche eingebracht werden, sofern sie vom ursprünglich vereinbarten Leistungsumfang gedeckt sind. Sofern erforderlich besteht die Möglichkeit eines Rebriefings vor Fertigung des Leistungsgegenstands. Anpassungen werden Bestandteil des ursprünglichen Vertrags, wenn beide Vertragsparteien in Textform zustimmen. Im Übrigen ist W&S Epic GmbH nur zur Herstellung der im Vertrag aufgelisteten Positionen verpflichtet. Darüberhinausgehende Leistungen müssen gesondert vereinbart und vergütet werden.
							</li>
							<li>
								Soweit nichts anderes vereinbart wurde, steht dem Kunden das Recht auf zwei Korrekturschleifen zu. Reklamationen hinsichtlich der künstlerischen Gestaltung sind nach der Durchführung der vereinbarten Korrekturschleifen grundsätzlich ausgeschlossen. Wünscht der Kunde darüber hinaus weitere Änderungen, so hat er die Mehrkosten zu tragen.
							</li>
							<li>
								Voraussetzung für die Tätigkeit von W&S Epic GmbH ist, dass der Kunde sämtliche für die Umsetzung des Projekts erforderliche Daten (Farbdefinition, Vorlagen, Grafiken etc.) W&S Epic GmbH vor Auftragsbeginn oder zu einem anderweitig vereinbarten Zeitpunkt vollständig in geeigneter Form zur Verfügung stellt. Kommt der Kunde dieser Verpflichtung nicht nach, kann W&S Epic GmbH dem Kunden den hierdurch entstehenden Zeitaufwand in Rechnung stellen.
							</li>
							<li>
								Sobald der vereinbarte Leistungsgegenstand fertiggestellt wurde, wird W&S Epic GmbH den Kunden zur Abnahme des Werks auffordern.
							</li>
							<li>
								Die Vergütung ist Gegenstand einer individualvertraglichen Vereinbar	ung zwischen den Parteien. Im Übrigen finden die gesetzlichen Vorschriften Anwendung.
							</li>
							<li>
								Vorbehaltlich abweichender Regelungen wird bei der Erstellung des Corporate Designs ein zeitlich, örtlich und inhaltlich unbeschränktes, ausschließliches Nutzungsrecht eingeräumt; einzelne grafische Elemente des Corporate Designs dürfen jedoch für die Erstellung anderer Werke verwendet werden, solange hierdurch keine Verwechslungsgefahr zum erstellten Corporate Design entsteht.
							</li>
							<li>
								Eine Übertragung der Nutzungsrechte an Dritte bedarf einer individualvertraglichen Vereinbarung.
							</li>
							<li>
								Die Nutzungsrechte gehen erst nach vollständiger Bezahlung der Vergütung auf den Kunden über.
							</li>
						</ol>
					</li>
				</ol>
				
				<h3>Teil 4 - Marketing</h3>
				<ol>
					<li class="head">
						SEO-Marketing
						<ol>
							<li>
								W&S Epic GmbH bietet dem Kunden u.a. Dienstleistungen im Bereich SEO-Marketing an. Im Rahmen der Leistungserbringung schuldet W&S Epic GmbH ausschließlich die Durchführung von Maßnahmen, die nach eigener Erfahrung von W&S Epic GmbH das Suchmaschinen-Ranking positiv beeinflussen können oder vom Kunden ausdrücklich angeordnet werden. Hierbei handelt es sich um eine Dienstleistung im Sinne von §§ 611 ff. BGB. Ein bestimmtes Ergebnis (z.B. ein bestimmtes Ranking in der Suchmaschinen-Trefferliste) wird im Rahmen der SEO-Dienstleistungen dagegen nur dann geschuldet, wenn dieses ausdrücklich zugesichert wurde. Marketing-Leistungen können von beiden Vertragsparteien mit einer Kündigungsfrist von einem (1) Monaten wieder abbestellt werden – es sei denn, es wurde eine individualvertragliche Vereinbarung getroffen.
							</li>
						</ol>
					</li>
					<li class="head">
						SEA-Kampagnen
						<ol>
							<li>
								W&S Epic GmbH bietet dem Kunden Dienstleistungen im Bereich von SEA-Kampagnen an. Im Rahmen der Leistungserbringung schuldet W&S Epic GmbH ausschließlich die Unterbreitung von Vorschlägen bzgl. werbewirksamer Keywords und nach Freigabe des Kunden die Durchführung der Maßnahme (Schaltung von Werbeanzeigen). Hierbei handelt es sich um Dienstleistungen im Sinne von §§ 611 ff. BGB. Ein bestimmtes Ergebnis (z.B. Verkaufszahlen) wird im Rahmen von SEA-Dienstleistungen nicht geschuldet, es sei denn, dies wurde ausdrücklich zugesichert. W&S Epic GmbH trifft nicht die Verpflichtung, die Rechtmäßigkeit von Keywords zu überprüfen. W&S Epic GmbH unterbreitet dem Kunden Vorschläge bzgl. der Buchung von Keywords. Die rechtliche Prüfung insbesondere auf die Markenrechte Dritter und Freigabe der Keywords obliegt dem Kunden vor Durchführung der Kampagne. Das für die vorliegend beschriebenen Leistungen vereinbarte Honorar beinhaltet nicht die Kosten für die Schaltung kostenpflichtiger Werbeanzeigen; vorbehaltlich abweichender Vereinbarungen sind diese Kosten vom Kunden zu tragen.
							</li>
						</ol>
					</li>
					<li class="head">
						E-Mail-Marketing
						<ol>
							<li>
								W&S Epic GmbH bietet dem Kunden die Planung und Durchführung von E-Mail-Marketing-Kampagnen an. Der konkrete Leistungsgegenstand wird individualvertraglich festgelegt. Für die Durchführung der E-Mail-Kampagnen benötigt W&S Epic GmbH einen Zugang zu den Newsletter-Listen und ggf. zum Newsletter- Dienstleister des Kunden. Bei der Konzeptionierung der E-Mail-Marketing-Kampagnen schuldet W&S Epic GmbH ausschließlich die Durchführung von Maßnahmen, die nach eigener Erfahrung von W&S Epic GmbH das angestrebte Ergebnis (z.B. Verkauf von Produkten, Generierung von Leads o.Ä.) fördern. Ein bestimmtes Ergebnis (z.B. die tatsächliche Generierung einer bestimmten Anzahl an Leads) wird dagegen nur dann geschuldet, wenn dieses ausdrücklich zugesichert wurde.
							</li>
							<li>
								W&S Epic GmbH haftet nicht für Fehler, die im Verantwortungsbereich des E-Mail-Dienstleisters oder im Machtbereich des Kunden geschehen sind. W&S Epic GmbH haftet insbesondere nicht für Abmahnungen oder Bußgelder, die aufgrund des Versands unerwünschter Werbemails erfolgen (z.B. bei der Versendung von E-Mails an Empfänger, die keine Einwilligung in Werbemails erteilt haben). Für die korrekte Pflege der Newsletter-Listen ist der Kunde – vorbehaltlich abweichender Individualvereinbarungen – selbst verantwortlich. Die Regelungen unter der Überschrift „Haftung / Freistellung“ bleiben hiervon unberührt.
							</li>
						</ol>
					</li>
					<li class="head">
						Social Media Marketing
						<ol>
							<li>
								W&S Epic GmbH stellt seinen Kunden unter anderem die technische Unterstützung bei der Erstellung und/oder Betreuung von Social-Media-Präsenzen zur Verfügung. Sofern der Kunde diese Leistungen in Anspruch nimmt, schuldet W&S Epic GmbH ausschließlich die technische Erstellung der Social-Media-Präsenzen und/oder das technische Einpflegen der vom Kunden zur Verfügung zu stellenden Inhalte. 
							</li>
							<li>
								Neben der Erstellung der Social-Media-Präsenzen kann auch das Posten im Namen und unter dessen Namen (sog. Ghost Posting) vereinbart werden. W&S Epic GmbH ist in der inhaltlichen Ausgestaltung frei, sofern es keine Vorgaben des Kunden gibt. Es besteht keine Verpflichtung, auf Posts von Dritten zu reagieren oder diese zu überwachen. Dies untersteht der Verantwortung des Kunden als Betreiber. 
							</li>
							<li>
								Sofern der Kunde Inhalte (Bilder, Texte, Videos, etc.) vorgibt, wird W&S Epic GmbH diese Inhalte nicht auf ihre inhaltliche oder rechtliche Richtigkeit prüfen. Insoweit wird ausdrücklich darauf hingewiesen, dass W&S Epic GmbH nicht berechtigt ist, den Kunden rechtlich zu beraten. Sollte W&S Epic GmbH in Einzelfällen dennoch feststellen, dass die vom Kunden bereitgestellten Inhalte gegen geltendes Recht verstoßen, kann W&S Epic GmbH das Einstellen solcher Inhalte verweigern.
							</li>
							<li>
								Alle Inhalte müssen vom Kunden abgenommen werden und werden hiernach von W&S Epic GmbH in die jeweiligen Präsenzen hochgeladen, wobei W&S Epic GmbH nur das technische Hochladen der Inhalte schuldet und auch nur hierfür verantwortlich ist; die Regelungen unter „Haftung / Freistellung“ bleiben unberührt. 
							</li>
							<li>
								Dienstanbieter im Sinne des § 10 TMG ist allein der Kunde. W&S Epic GmbH wird lediglich als Auftragsverarbeiter des Kunden tätig.
							</li>
						</ol>
					</li>
					<li class="head">
						Schaltung von Werbeanzeigen
						<ol>
							<li>
								W&S Epic GmbH unterstützt den Kunden bei Anzeigenschaltungen in Social-Media-Portalen, Suchmaschinen und sonstigen Medien („Anzeigen“).
							</li>
							<li>
								W&S Epic GmbH berät den Kunden dahingehend, wie dieser seine Anzeigen so ausgestaltet, dass diese eine möglichst hohe Sichtbarkeit aufweisen. Bestimmte Ergebnisse (z.B. Verkaufszahlen, Leads) sind hierbei nicht geschuldet. 
							</li>
							<li>
								W&S Epic GmbH unterstützt den Kunden auch bei der Konzeptionierung der Texte und Bilder für die Anzeigen. Die Auswahl der Inhalte für die Anzeigen (Bilder, Texte, Videos, Impressen etc.), obliegt jedoch allein dem Kunden. W&S Epic GmbH wird diese Inhalte aber auch die Anzeigen insgesamt nicht auf ihre inhaltliche oder rechtliche Richtigkeit prüfen. Insoweit wird ausdrücklich darauf hingewiesen, dass W&S Epic GmbH nicht berechtigt ist, den Kunden rechtlich zu beraten. Sollte W&S Epic GmbH in Einzelfällen dennoch feststellen, dass die vom Kunden bereitgestellten Inhalte und/oder die Anzeigen gegen geltendes Recht verstoßen, kann W&S Epic GmbH das Einstellen solcher Inhalte bzw. Erstellen der Anzeigen verweigern.
							</li>
							<li>
								Alle Inhalte müssen vom Kunden abgenommen werden und werden hiernach von W&S Epic GmbH in die jeweiligen Werbekanäle hochgeladen, wobei W&S Epic GmbH nur das technische Hochladen der Inhalte schuldet und auch nur hierfür verantwortlich ist; die Regelungen unter „Haftung / Freistellung“ bleiben unberührt. 
							</li>
							<li>
								Das für die vorliegend beschriebenen Leistungen vereinbarte Honorar beinhaltet nicht die Kosten für die Schaltung kostenpflichtiger Werbeanzeigen; vorbehaltlich abweichender Vereinbarungen sind diese Kosten vom Kunden zu tragen.
							</li>
							<li>
								Dienstanbieter im Sinne des § 10 TMG ist allein der Kunde. W&S Epic GmbH wird lediglich als Auftragsverarbeiter des Kunden tätig.
							</li>
						</ol>
					</li>
				</ol>
				
				<h3>Teil 5 - Beratung</h3>
				<ol>
					<li class="head">
						Allgemeine Beratungsleistungen
						<ol>
							<li>
								W&S Epic GmbH bietet dem Kunden Beratungsleistungen in den Bereichen Marketing und Werbung an. Im Rahmen der Leistungserbringung schuldet W&S Epic GmbH ausschließlich eine Beratung nach bestem Wissen und Gewissen und – sofern einschlägig – auf Grundlage der aktuellen Erkenntnislage bzw. dem Stand der Technik. Bei den Beratungsleistungen handelt es sich um eine Dienstleistung im Sinne von §§ 611 ff. BGB. Ein bestimmtes Ergebnis ist wird nur geschuldet, wenn es ausdrücklich zugesichert wurde. Auch die Beratung auf Grundlage oder unter Berücksichtigungen spezifischer Normen (z.B. DIN-Normen oder berufsrechtlicher Regelungen) ist nur geschuldet, wenn dies ausdrücklich vereinbart wurde
							</li>
						</ol>
					</li>
				</ol>
				
				<h3>Teil 6 - Sonstige Bestimmungen</h3>
				<ol>
					<li class="head">
						Preise und Vergütung
						<ol>
							<li>
								Die Vergütung für die Leistungen von W&S Epic GmbH ist Gegenstand einer individualvertraglichen Vereinbarung zwischen den Parteien und richtet sich grundsätzlich nach dem Angebot. 
							</li>
						</ol>
					</li>
					<li class="head">
						Abnahme
						<ol>
							<li>
								Soweit eine Werkleistung vereinbart wurde, kann W&S Epic GmbH verlangen, dass die Abnahme in Schriftform erfolgt; die schriftliche Abnahme ist nur geschuldet, wenn W&S Epic GmbH den Kunden hierzu auffordert. Die Abnahmebestimmungen des Bürgerlichen Gesetzbuches bleiben im Übrigen unberührt. Die Abnahmefrist im Sinne des § 640 Abs. 2 S. 1 BGB wird auf 2 Wochen ab Mitteilung über die Fertigstellung des Werks festgelegt, sofern im Einzelfall aufgrund besonderer Umstände nicht eine längere Abnahmefrist erforderlich ist, die W&S Epic GmbH dem Kunden in diesem Fall gesondert mitteilen wird. Sofern sich der Kunde innerhalb dieser Frist nicht äußert oder die Abnahme nicht wegen eines Mangels verweigert, gilt das Werk als abgenommen. 
							</li>
						</ol>
					</li>
					<li class="head">
						Mängelgewährleistung
						<ol>
							<li>
								Ein unwesentlicher Mangel begründet keine Mängelansprüche. Die Wahl der Art der Nacherfüllung liegt bei W&S Epic GmbH. Die Verjährungsfrist für Mängel und sonstige Ansprüche beträgt ein (1) Jahr; diese Verjährungsverkürzung gilt nicht für Ansprüche, die aus Vorsatz, grober Fahrlässigkeit oder aus der Verletzung von Leib, Leben oder Gesundheit durch W&S Epic GmbH resultieren. Die Verjährung beginnt nicht erneut, sofern im Rahmen der Mängelhaftung eine Nacherfüllung erfolgt. Im Übrigen bleibt die gesetzliche Mängelgewährleistung unberührt.
							</li>
						</ol>
					</li>
					<li class="head">
						Vertragslaufzeit bei Dauerschuldverhältnissen
						<ol>
							<li>
								Vorbehaltlich abweichender Bestimmungen in und außerhalb dieser AGB haben Dauerschuldverhältnisse eine Mindestlaufzeit von 12 Monaten. Die Kündigungsfrist beträgt 3 Monate. Wird der Vertrag nicht fristgerecht zum Laufzeitende gekündigt, verlängert er sich automatisch um weitere 12 Monate. Das Recht zur außerordentlichen fristlosen Kündigung aus wichtigem Grund bleibt unberührt
							</li>
						</ol>
					</li>
					<li class="head">
						Rechteeinräumung, Eigenwerbung und Erwähnungsrecht
						<ol>
							<li>
								W&S Epic GmbH räumt dem Kunden – nach vollständiger Bezahlung des Auftrags durch den Kunden – an den entsprechenden Arbeitsergebnissen grundsätzlich ein einfaches, nicht übertragbares Nutzungsrecht ein. Weitergehende Rechte können individualvertraglich vereinbart werden.
							</li>
							<li>
								Sofern nichts Abweichendes vereinbart wurde, erteilt der Kunde W&S Epic GmbH ausdrücklich die Erlaubnis, das Projekt zum Zwecke der Eigenwerbung (Referenzen/Portfolio) in angemessener Weise öffentlich darzustellen. Insbesondere ist W&S Epic GmbH dazu berechtigt, mit der Geschäftsbeziehung zu dem Kunden zu werben und auf allen erstellten Werbemitteln und bei allen Werbemaßnahmen auf sich als Urheber hinzuweisen, ohne dass dem Kunden dafür ein Entgeltanspruch zusteht.
							</li>
							<li>
								Ferner ist W&S Epic GmbH berechtigt, den eigenen Namen mit Verlinkung in angemessener Weise im Footer und im Impressum der von W&S Epic GmbH erstellten Webseite(n) zu platzieren, ohne dass dem Kunden hierfür ein Entgeltanspruch zusteht.
							</li>
						</ol>
					</li>
					<li class="head">
						Vertraulichkeit
						<ol>
							<li>
								W&S Epic GmbH wird alle ihm zur Kenntnis gelangenden Geschäftsvorgänge, insbesondere, aber nicht ausschließlich, Druckunterlagen, Layouts, Storyboards, Zahlenmaterial, Zeichnungen, Tonbänder, Bilder, Videos, DVD, CD-ROMs, Speicherkarten, Passwörter, interaktive Produkte und solche anderen Unterlagen, welche Filme und/oder Hörspiele und/oder sonstige urheberrechtlich geschützte Materialien des Kunden oder mit ihm verbundenen Unternehmen enthalten, streng vertraulich behandeln. W&S Epic GmbH verpflichtet sich, die Geheimhaltungspflicht sämtlichen Angestellten und/oder Dritten (bspw. Lieferanten, Grafikern, Programmierern, Filmproduzenten, Tonstudios etc.), die Zugang zu den vorbezeichneten Geschäftsvorgängen haben, aufzuerlegen. Die Geheimhaltungspflicht gilt zeitlich unbegrenzt über die Dauer dieses Vertrages hinaus.
							</li>
						</ol>
					</li>
					<li class="head">
						Haftung / Freistellung
						<ol>
							<li>
								W&S Epic GmbH haftet aus jedem Rechtsgrund uneingeschränkt bei Vorsatz oder grober Fahrlässigkeit, bei vorsätzlicher oder fahrlässiger Verletzung des Lebens, des Körpers oder der Gesundheit, aufgrund eines Garantieversprechens, soweit diesbezüglich nichts Anderes geregelt ist oder aufgrund zwingender Haftung wie etwa nach dem Produkthaftungsgesetz. Verletzt W&S Epic GmbH fahrlässig eine wesentliche Vertragspflicht, ist die Haftung auf den vertragstypischen, vorhersehbaren Schaden begrenzt, sofern nicht gemäß vorstehendem Satz unbeschränkt gehaftet wird. Wesentliche Vertragspflichten sind Pflichten, die der Vertrag W&S Epic GmbH nach seinem Inhalt zur Erreichung des Vertragszwecks auferlegt, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Kunde regelmäßig vertrauen darf. Im Übrigen ist eine Haftung von W&S Epic GmbH ausgeschlossen. Vorstehende Haftungsregelungen gelten auch im Hinblick auf die Haftung von W&S Epic GmbH für seine Erfüllungsgehilfen und gesetzlichen Vertreter. 
							</li>
							<li>
								Der Kunde stellt W&S Epic GmbH von jeglichen Ansprüchen Dritter frei, die gegen W&S Epic GmbH aufgrund von Verstößen des Kunden gegen diese AGB oder gegen geltendes Recht geltend gemacht werden.
							</li>
						</ol>
					</li>
					<li class="head">
						Schlussbestimmungen
						<ol>
							<li>
								Die zwischen W&S Epic GmbH und den Kunden geschlossenen Verträge unterliegen dem materiellen Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts. 
							</li>
							<li>
								Sofern der Kunde Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen ist oder keinen allgemeinen Gerichtsstand in Deutschland hat, vereinbaren die Parteien den Sitz von W&S Epic GmbH als Gerichtsstand für sämtliche Streitigkeiten aus diesem Vertragsverhältnis; ausschließliche Gerichtsstände bleiben hiervon unberührt.
							</li>
							<li>
								Es wird darauf hingewiesen, dass bei der Beauftragung von (Web-)Designern in der Regel eine Abgabe an die Künstlersozialkasse zu zahlen ist. Hierbei handelt es sich um eine gesetzliche Abgabe, die im „Gesetz über die Sozialversicherung der selbstständigen Künstler und Publizisten“ (KSVG) festgeschrieben ist. Diese ist vom Kunden selbstständig bei der Künstlersozialkasse zu melden. Auf Höhe und Umfang dieser Abgabe hat W&S Epic GmbH keinen Einfluss. Die Meldung und Bezahlung des Beitrags obliegen allein dem Kunden. 
							</li>
							<li>
								W&S Epic GmbH ist berechtigt, diese AGB aus sachlich gerechtfertigten Gründen (z.B. Änderungen in der Rechtsprechung, Gesetzeslage, Marktgegebenheiten oder der Geschäfts- oder Unternehmensstrategie) und unter Einhaltung einer angemessenen Frist zu ändern. Bestandskunden werden hierüber spätestens zwei Wochen vor Inkrafttreten der Änderung per E-Mail benachrichtigt. Sofern der Bestandskunde nicht innerhalb der in der Änderungsmitteilung gesetzten Frist widerspricht, gilt seine Zustimmung zur Änderung als erteilt. Widerspricht er, treten die Änderungen nicht in Kraft; W&S Epic GmbH ist in diesem Fall berechtigt, den Vertrag zum Zeitpunkt des Inkrafttretens der Änderung außerordentlich zu kündigen. Die Benachrichtigung über die beabsichtigte Änderung dieser AGB wird auf die Frist und die Folgen des Widerspruchs oder seines Ausbleibens hinweisen.
							</li>
						</ol>
					</li>
				</ol>
				
				<p>
					Stand: September 2021
				</p>
        </>
    )
}