import React, { useEffect, useRef, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import MultiRangeSlider from "./MultiRangeSlider/MultiRangeSlider";

import FileUploadImage from './../assets/images/file_bg.svg';
import API from './../classes/Api';
import Loading from './../components/Loading';
import Creative from "./Creative";

export default function Project( {project, deleteProject, deleteCreative, AddSnackbar, updateProjectCreatives, expand} ) {
    const [isLoading, setIsLoading] = useState( false );
    const [isExpanded, setIsExpanded] = useState( expand );
    const [filter, setFilter] = useState({minAge: 0, maxAge: 99, gender:"männlich weiblich diverse", sortedBy: "LIKES"});
    const fileInput = useRef( null );
    let filteredCreatives = project.creatives || [];

    const minValRef = useRef(null);
    const maxValRef = useRef(null);
    let range = useRef(null);

    // Convert to percentage
    const getPercent = useCallback((value) => {
        return Math.round(((value - 0) / (99 - 0)) * 100);
    },[filter]);

    const resetFilter = () => {
        setFilter({minAge: 0, maxAge: 99, gender:"männlich weiblich diverse", sortedBy: "LIKES"});
    }
    
    const copyToClipboard = (link) => {
        AddSnackbar("🔗 Link wurde Kopiert");
        navigator.clipboard.writeText(link);
    }

    const deleteProjectHandler = projectId => {
        if( window.confirm("Willst du diese Umfrage wirklich löschen?") ) {
            deleteProject( projectId );
        }
    }

    const filterMinAgeHandler = (e) => {
        const value = Math.min(+e.target.value, filter.maxAge - 1);
        setFilter({...filter, minAge: value });
        e.target.value = value.toString();
    }
    
    const filterMaxAgeHandler = (e) => {
        const value = Math.max(+e.target.value, filter.minAge + 1);
        setFilter({...filter, maxAge: value });
        e.target.value = value.toString();
    }

    const sortFilterResult = ( creatives ) => {
        var sortedCreatives = creatives;
        if( filter.sortedBy === "LIKES" ) {
            sortedCreatives.sort((a, b) => (a.likes < b.likes) ? 1 : -1);
        } else if ( filter.sortedBy === "DISLIKES" ) {
            sortedCreatives.sort((a, b) => (a.dislikes < b.dislikes) ? 1 : -1);
        }
        return sortedCreatives;
    }

    const onFileChangeHandler = (projectID, files) => {
        if( !projectID || !files ) { return; }
        var formData = new FormData();
        formData.append("task", "setCreative");
        formData.append("projectId", projectID);
        Object.keys(files).map(key => {
            if( files[key] ) {
                formData.append("images[]", files[key]);
            }
        });
        setIsLoading( true );
        API.setCreatives( formData, function(res){
            if( res.status === 200 && res.response ) {
                var response = JSON.parse( res.response );
                if( response.success ) {
                    updateProjectCreatives(projectID, response.data, setIsLoading( false ));
                }
            }
        } );
    }
    
    // Set width of the range to decrease from the left side
    useEffect(() => {
        if (maxValRef.current) {
        const minPercent = getPercent(filter.minAge);
        const maxPercent = getPercent(+maxValRef.current.value);
        if (range.current) {
            range.current.style.left = `${minPercent}%`;
            range.current.style.width = `${maxPercent - minPercent}%`;
        }
        }
    }, [filter, getPercent]);

    // Set width of the range to decrease from the right side
    useEffect(() => {
        if (minValRef.current) {
        const minPercent = getPercent(+minValRef.current.value);
        const maxPercent = getPercent(filter.maxAge);

        if (range.current) {
            range.current.style.width = `${maxPercent - minPercent}%`;
        }
        }
    }, [filter, getPercent]);

    filteredCreatives = filteredCreatives.map(creative => {creative.weight = 0; return creative;});
    
    filteredCreatives = filteredCreatives.map(creative => {
        creative.likes = creative.votes.filter(vote => {
            if( 
                vote.result === "LIKE" 
                && vote.user 
                && vote.user.age >= filter.minAge 
                && vote.user.age <= filter.maxAge 
                && filter.gender.includes(vote.user.gender)
            ) {
                return vote;
            }
        }).length; 

        creative.dislikes = creative.votes.filter(vote => {
            if( 
                vote.result === "DISLIKE" 
                && vote.user 
                && vote.user.age >= filter.minAge 
                && vote.user.age <= filter.maxAge  
                && filter.gender.includes(vote.user.gender)
            ) {
                return vote;
            }
        }).length; 
        return creative;
    });

    filteredCreatives = sortFilterResult( filteredCreatives );

    if( isExpanded ) {
        return(
            <li key={`PROJECT_${project.id}`} className={`we-project${isExpanded ? " is--expanded" : ""}`}>
                {isLoading ? <div className="we-loading__wrapper"><Loading /></div> : ""}
                <div className="we-project__header">
                    <p className="we-project__title">{project.name}</p>
                    <div>
                        <button className="we-btn" onClick={() => copyToClipboard(`${window.location.origin}${process.env.PUBLIC_URL}/vote/${project.id}`)}>
                            Umfrage teilen
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                        </button>
                        <button className="we-btn we-project__delete-btn" onClick={() => deleteProjectHandler( project.id )}>
                            Umfrage löschen
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                        </button>
                        <Link className="we-btn" to={`${process.env.PUBLIC_URL}/backend`}>
                            Schließen
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                        </Link>
                    </div>
                </div>
                <div className="we-project__filter">
                    <p>Filter</p>
                    <div className="we-project-filter">
                        <div>
                            <p>Age</p>
                            <div className="we-multirange-slider">
                                <input
                                    type="range"
                                    min={0}
                                    max={99}
                                    value={filter.minAge}
                                    ref={minValRef}
                                    onChange={filterMinAgeHandler}
                                    className={`${
                                    filter.minAge > 99 - 100 ? "thumb thumb--zindex-5" : "thumb thumb--zindex-3"
                                    }`}
                                />
                                <input
                                    type="range"
                                    min={0}
                                    max={99}
                                    value={filter.maxAge}
                                    ref={maxValRef}
                                    onChange={filterMaxAgeHandler}
                                    className="thumb thumb--zindex-4"
                                />
                                <div className="slider">
                                    <div className="slider__track" />
                                    <div ref={range} className="slider__range" />
                                    <div className="slider__left-value">{filter.minAge}</div>
                                    <div className="slider__right-value">{filter.maxAge}</div>
                                </div>
                            </div>
                        </div>
                        <div className="we-project-filter__gender">
                            <p>Sex</p>
                            <label>
                                <input type="checkbox" name="gender" value="weiblich" defaultChecked onChange={(e) => e.target.checked ? setFilter({...filter, gender:filter.gender+" weiblich"}) : setFilter({...filter, gender:filter.gender.replace("weiblich", "")})} />
                                <svg id="Gruppe_86" data-name="Gruppe 86" xmlns="http://www.w3.org/2000/svg" width="20.499" height="30.97" viewBox="0 0 20.499 30.97">
                                    <g id="Gruppe_80" data-name="Gruppe 80">
                                        <path id="Pfad_22" data-name="Pfad 22" d="M20.5,10.273a10.25,10.25,0,1,0-20.5,0A10.358,10.358,0,0,0,8.836,20.44v3.268H4.988V26.7H8.836v4.27h2.847V26.7h3.848V23.708H11.683V20.44A10.214,10.214,0,0,0,20.5,10.273M10.24,17.607a7.334,7.334,0,1,1,7.334-7.334,7.34,7.34,0,0,1-7.334,7.334" fill="#b5b5b5"/>
                                    </g>
                                </svg>

                            </label>
                            <label>
                                <input type="checkbox" name="gender" value="männlich" defaultChecked onChange={(e) => e.target.checked ? setFilter({...filter, gender:filter.gender+" männlich"}) : setFilter({...filter, gender:filter.gender.replace("männlich", "")})} />
                                <svg id="Gruppe_87" data-name="Gruppe 87" xmlns="http://www.w3.org/2000/svg" width="25.41" height="25.423" viewBox="0 0 25.41 25.423">
                                    <defs>
                                    </defs>
                                    <g id="Gruppe_82" data-name="Gruppe 82" transform="translate(0 0)">
                                        <path id="Pfad_23" data-name="Pfad 23" d="M25.4,0H14.841l.02,2.952,5.575.02L16.449,6.958a10.237,10.237,0,1,0,2.036,2.03L22.472,5v5.561H25.41Z" transform="translate(0 0)" fill="#b5b5b5"/>
                                        <path id="circle" data-name="Pfad 24" d="M16.951,24.388a7.338,7.338,0,1,1,0-10.378,7.35,7.35,0,0,1,0,10.378" transform="translate(-1.51 -4.047)"/>
                                    </g>
                                </svg>
                            </label>
                            <label>
                                <input type="checkbox" name="gender" value="diverse" defaultChecked onChange={(e) => e.target.checked ? setFilter({...filter, gender:filter.gender+" diverse"}) : setFilter({...filter, gender:filter.gender.replace("diverse", "")})} />
                                <svg id="Gruppe_88" data-name="Gruppe 88" xmlns="http://www.w3.org/2000/svg" width="25.41" height="35.782" viewBox="0 0 25.41 35.782">
                                    <g id="Gruppe_84" data-name="Gruppe 84" transform="translate(0 0)">
                                        <path id="Pfad_25" data-name="Pfad 25" d="M20.5,17.576a10.25,10.25,0,1,0-20.5,0A10.358,10.358,0,0,0,8.836,27.743v3.268H4.988V34H8.836v4.27h2.847V34h3.848V31.011H11.683V27.743A10.214,10.214,0,0,0,20.5,17.576M10.24,24.91a7.334,7.334,0,1,1,7.334-7.334A7.34,7.34,0,0,1,10.24,24.91" transform="translate(0 -2.491)" fill="#b5b5b5"/>
                                        <path id="Pfad_26" data-name="Pfad 26" d="M25.4,0H14.841l.02,2.952,5.575.02L16.449,6.958a10.237,10.237,0,1,0,2.036,2.03L22.472,5v5.561H25.41Z" transform="translate(0 0)" fill="#b5b5b5"/>
                                        <path id="circle" data-name="Pfad 27" d="M16.951,24.388a7.338,7.338,0,1,1,0-10.378,7.35,7.35,0,0,1,0,10.378" transform="translate(-1.51 -4.047)"/>
                                    </g>
                                </svg>

                            </label>
                        </div>
                        <div className="we-project-filter__sorted-by">
                            <p>Sorted by</p>
                            <label>
                                <input type="radio" name="sortedBy" value="LIKES" checked={filter.sortedBy === "LIKES"} onChange={(e) => setFilter({...filter,sortedBy:e.target.value ? "LIKES" : ""})}/>
                                <span className="like">
                                    <svg id="Ebene_1" dataname="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.28 141.29"><polygon className="cls-1" points="141.28 0 31.78 25.36 69.44 63.03 65.72 59.3 0 125.02 16.26 141.28 81.98 75.57 115.92 109.51 141.28 0"></polygon></svg>
                                </span>
                            </label>
                            <label>
                                <input type="radio" name="sortedBy" value="DISLIKES" checked={filter.sortedBy === "DISLIKES"} onChange={(e) => setFilter({...filter,sortedBy:e.target.value ? "DISLIKES" : ""})} />
                                <span className="dislike">
                                    <svg id="Ebene_1" dataname="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 141.28 141.29"><polygon className="cls-1" points="141.28 0 31.78 25.36 69.44 63.03 65.72 59.3 0 125.02 16.26 141.28 81.98 75.57 115.92 109.51 141.28 0"></polygon></svg>
                                </span>
                            </label>
                        </div>
                        <div className="we-project-filter__action">
                            <button onClick={resetFilter}>Filter zurücksetzten</button>
                        </div>
                    </div>
                </div>
                <ul className="we-project__creatives">
                    {filteredCreatives && filteredCreatives.length > 0 ? 
                        filteredCreatives.map( creative => <Creative key={`PROJECT_${project.id}_CREATIVE_${creative.id}`} projectId={project.id} creative={creative} deleteCreative={deleteCreative} isExpanded={isExpanded} />)
                        : ""
                    }
                    <li>
                        <div className={`we-input-file${isLoading ? " is--loading" : ""}`}>
                            <img src={FileUploadImage} alt="" title="" />
                            <input type="file" name="images[]" ref={fileInput} disabled={isLoading} required multiple onChange={(e) => onFileChangeHandler(project.id, e.target.files)}/>
                        </div>
                    </li>
                </ul>
            </li>
        );
    } else {
        return(
            <li key={`PROJECT_${project.id}`} className="we-project">
                <Link className="we-project__expand-btn" to={`${process.env.PUBLIC_URL}/backend/${project.id}`}></Link>
                <div className="we-project__header">
                    <p className="we-project__title">{project.name}</p>
                    <button onClick={() => copyToClipboard(`${window.location.origin}${process.env.PUBLIC_URL}/vote/${project.id}`)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-copy"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                    </button>
                </div>
                {project.creatives && project.creatives.length > 0 ? 
                    <ul className="we-project__creatives">{project.creatives[0] ? <Creative key={`PROJECT_${project.id}_CREATIVE_${project.creatives[0].id}`} projectId={project.id} creative={project.creatives[0]} deleteCreative={deleteCreative} isExpanded={isExpanded} /> : ""}</ul>
                    : 
                    <div className={`we-input-file${isLoading ? " is--loading" : ""}`}>
                        <img src={FileUploadImage} alt="" title="" />
                        <input type="file" name="images[]" ref={fileInput} disabled={isLoading} required multiple onChange={(e) => onFileChangeHandler(project.id, e.target.files)}/>
                    </div>
                }   
            </li>
        );
    }
}