import React from "react";
import { Link } from "react-router-dom";

export default function Header({user, logout, children}) {
    return(
        <header className="we-header">
        <Link to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="93.553" height="39.641" viewBox="0 0 93.553 39.641">
            <g id="Gruppe_1" data-name="Gruppe 1" transform="translate(-353.268 72.374)">
              <path id="Pfad_4" data-name="Pfad 4" d="M373.088-72.374a19.821,19.821,0,0,0-19.82,19.821,19.821,19.821,0,0,0,19.82,19.821,19.821,19.821,0,0,0,19.821-19.821A19.821,19.821,0,0,0,373.088-72.374Zm.664,27.918h-3.059a.489.489,0,0,1-.542-.39l-2.516-9.806L365.1-44.847a.48.48,0,0,1-.521.39h-3.059a.451.451,0,0,1-.477-.39l-3.492-14.4c-.065-.238,0-.39.238-.39h2.777a.447.447,0,0,1,.5.39l2.17,9.654,2.56-9.762a.35.35,0,0,1,.368-.282h2.929a.373.373,0,0,1,.39.282l2.538,9.762,2.191-9.654c.065-.238.152-.39.477-.39h2.755c.261,0,.326.152.261.39l-3.492,14.4A.444.444,0,0,1,373.752-44.456Zm14.483-14.209h-7.7l-1.449,6.139h6.941c.13,0,.19.065.156.217l-.107.52a.287.287,0,0,1-.3.217h-6.942l-1.45,6.139h7.7c.151,0,.19.065.156.216l-.131.542c-.013.152-.1.217-.257.217h-8.46c-.152,0-.212-.065-.178-.217l3.533-14.751c.013-.152.1-.217.278-.217h8.439c.152,0,.212.065.178.217l-.131.542A.251.251,0,0,1,388.236-58.666Z" transform="translate(0 0)" fill="#fff"/>
              <text id="VOTE" transform="translate(397.821 -46.452)" fill="#fff" fontSize="20" fontFamily="Crossten-BoldItalic, Crossten" fontWeight="700" fontStyle="italic"><tspan x="0" y="0">VOTE</tspan></text>
            </g>
          </svg>
        </Link>
        <div className="we-header__wrapper">
          {children}
          {logout ? 
            (user) ? 
              <button onClick={logout}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-out"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
              </button> 
            : 
              <Link to="/backend">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-log-in"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path><polyline points="10 17 15 12 10 7"></polyline><line x1="15" y1="12" x2="3" y2="12"></line></svg>
              </Link>
          : ""}
        </div>
        
      </header>
    )
}