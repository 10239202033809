
export default function CreateVote({createProjectSubmitHandler, projectName, setProjectName, projectDescription, setProjectDescription, setShowCreateProject}) {
    return(
        <form className="we-voting-backend__form" onSubmit={createProjectSubmitHandler} id="project-form">
            <button onClick={() => setShowCreateProject(false)} type="button">Close</button>
            <h2>Neu Umfrage erstellen</h2>
            <input type="hidden" name="task" value="setProject" />
            <label className="we-input">
                <span>Name:</span>
                <input type="text" name="name" value={projectName} onChange={(e) => setProjectName(e.target.value)} />
            </label>
            <label className="we-input">
                <span>Beschreibung</span>
                <textarea name="description" value={projectDescription} onChange={(e) => setProjectDescription( e.target.value )}></textarea>
            </label>
            <button className="we-btn white is--round" type="submit">Umfrage erstellen</button>
        </form>
    )
}